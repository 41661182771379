#shareModalOverlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: visible;
    background: rgba(#000, 0);
    z-index: 201;
    visibility: hidden;
    transition: background 300ms, visibility 0ms ease 300ms;
}

#shareModal {
    position: fixed;
    left: 50%;
    top: 50%;
    margin: 20px 0 0 0;
    padding: 100px 0px;
    width: 500px;
    max-width: calc(100% - 40px);
    max-height: 80%;
    text-align: center;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    z-index: 202;
    transform: translate(-50%, -50%);
	transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 250ms, margin 250ms, visibility 0ms ease 250ms;

    @media #{$down700} {
        width: 420px;
        padding: 70px;
    }

    @media #{$down500} {
        padding: 70px 20px 50px 20px;
    }

    @media #{$down400} {
        padding: 60px 20px 40px 20px;
    }

    .h3{
        margin-top: 0;
    }

    .h4 {
        margin-bottom: 40px;
		font-family: $revolution;
		font-size: 30px;

        @media #{$down1400} {
            margin-bottom: 30px;
            font-size: 30px;
        }

        @media #{$down700} {
            font-size: 28px;
        }

        @media #{$down600} {
            margin-bottom: 20px;
        }

        @media #{$down400} {
            font-size: 24px;
        }
    }

    >a {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 50px;
        line-height: 50px;

        .icon {
            width: 35px;
            height: 35px;
            stroke: $apfBlue;
            vertical-align: middle;
            stroke-width: 8px;
        }
    }

	.medias-sociaux {
		display: flex;
		padding: 0 20px;
		justify-content: center;
		a {
			margin: 0 10px;
			display: inline-block;
			position: relative;
			width: 50px;
			height: 50px;
			@media #{$down400} {
				width: 40px;
				height: 40px;
			}
			svg{
				width: 35px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				.fill{
                    fill: $apfDarkBlue;
                    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
				}
			}

			@media #{$down600} {
				margin: 4px 6px;
			}

			&:hover{
				svg{
                    .fill{
                        fill: #86909d;
                    }
                }
			}
		}
	}
}

.show-shareModal {
    #shareModalOverlay {
        visibility: visible;
        background: rgba(#000, 0.5);
        transition: background 400ms;
    }

    #shareModal {
        opacity: 1;
        margin-top: 0;
        visibility: visible;
        transition: opacity 400ms, margin 400ms;
        transition: opacity 400ms, margin 400ms;
    }
}

#shareCloseBtn{
	svg{
		.fill{
			fill: $apfBlue;
			stroke: $apfBlue;
		}
	}
}