.heading.innovation{
    @media #{$down1024} { padding-top: 0; }
    .headingContent{
        @media #{$down1024} {
            .responsiveGrid{
                display: flex;
                flex-wrap: wrap;
                margin-left: -20px;
                margin-right: -20px;
            }
        }

        h3{
            font-size: 45px;
            font-weight: normal;
        }
        .fixedTitle{
            position: sticky;
            top: 60px;
            @media #{$down1024} { position: static; }
        }
        p {
            padding-right: 100px;
            color: #fff;
            @media #{$down1024} { padding-right: 0; }
        }

        .innovationBlock{
            margin-top: 50px;
            @media #{$down1024} {
                width: 50%;
                padding-left: 20px;
                padding-right: 20px;
            }
            @media #{$down768} {
                width: 100%;
            }
            .icon{
                padding-left: 20px;
                padding-top: 12px;
                position: relative;
                svg{
                    width: 62px;
                    position: relative;
                    z-index: 1;
                }
                &::after{
                    content:'';
                    display: block;
                    height: 70px;
                    width: 70px;
                    background-color: $apfBlue;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 70px;
                    z-index: -1;
                }
            }
            h4{
                font-size: 30px;
                margin-top: 20px;
                font-weight: bold;
            }
            .text{
                color: #8c9092;
                font-size: 15px;
                line-height: 1.666666em //25px/15px
            }
            &:first-child{
                margin-top: 0;
                @media #{$down1024} { margin-top: auto; }
                @media #{$down768} { margin-top: 75px; }
            }
        }
    }
    .decoration{
        background-position: 0 3px; 
    }
}

section.lookout{
    
    color: white;
    position: relative;

    .photoContainer{
        span{
            display: block;
            width: 100%;
            height: 525px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            @media #{$down768} { height: 300px; }
        }
    }

    .newTendencies{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .left{
            width: 40%;
        }
        .right{
            width: 60%;
            display: flex;
            justify-content: center;
            .content{
                padding: 60px;
                padding-left: 100px;

                @media #{$down768} {
                    padding: 0;
                    padding-left: 32px;
                }

                p {
                    color: #fff;
                }
            }
        }
        img{
            max-width: 100%;
        }

        @media #{$down768} {
            .left{
                display: none;
            }
            .right{
                width: 100%;
            }
        }
    }

    &:after{
        content: '';
        background-color: $apfBlue;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 262px;
        z-index: -1;
        @media #{$down768} { top: 150px; }
    }

    .decoration{
        left: 0;
        top: 262px;
        bottom: 0;
        width: 270px;
        background-position: 0 6px;
        @media #{$down768} { top: 150px; width: 149px; }
    }
}