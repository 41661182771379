// GRILLE _________________________
.row {
	&.fixPadding {
		width: calc(100% + #{$gaps} + #{$gaps});
		margin-left: -$gaps;
		margin-right: -$gaps;
	}

	&:after {content: ''; display: block; clear: both; }
}


// Créer la largeurs des 24 colonnes
@for $i from 1 through 24 {
	.col#{$i} { width: ($i / 24 * 100%); }
}

// Donnant les propriétés communes à chaque colonne
.col, .col1, .col2, .col3, .col4, .col5, .col6, .col7,  .col8,  .col9,  .col10, .col11, .col12,
.col13, .col14, .col15, .col16, .col17, .col18, .col19, .col20, .col21, .col22, .col23, .col24{
	display: block; float: left; min-height: 1px; padding: 0 $gaps;
}

// Ajoute un margin top sur tout les éléments de la deuxième rangé et plus
@mixin setMarginTop($value, $nbCol) {
	&:nth-child(-n + #{$nbCol}) { margin-top: 0; }
	&:not(:nth-child(-n + #{$nbCol})) { margin-top: $value; }
}




// Media query 1800
@media #{$down1800}{
	@for $i from 1 through 24 {
		.col#{$i}_1800 { width: ($i / 24 * 100%); }
	}

	.col1_1800,  .col2_1800,  .col3_1800,  .col4_1800,  .col5_1800,  .col6_1800,
	.col7_1800,  .col8_1800,  .col9_1800,  .col10_1800, .col11_1800, .col12_1800,
	.col13_1800, .col14_1800, .col15_1800, .col16_1800, .col17_1800, .col18_1800,
	.col19_1800, .col20_1800, .col21_1800, .col22_1800, .col23_1800, .col24_1800{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 1700
@media #{$down1700}{
	@for $i from 1 through 24 {
		.col#{$i}_1700 { width: ($i / 24 * 100%); }
	}

	.col1_1700,  .col2_1700,  .col3_1700,  .col4_1700,  .col5_1700,  .col6_1700,
	.col7_1700,  .col8_1700,  .col9_1700,  .col10_1700, .col11_1700, .col12_1700,
	.col13_1700, .col14_1700, .col15_1700, .col16_1700, .col17_1700, .col18_1700,
	.col19_1700, .col20_1700, .col21_1700, .col22_1700, .col23_1700, .col24_1700{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 1600
@media #{$down1600}{
	@for $i from 1 through 24 {
		.col#{$i}_1600 { width: ($i / 24 * 100%); }
	}

	.col1_1600,  .col2_1600,  .col3_1600,  .col4_1600,  .col5_1600,  .col6_1600,
	.col7_1600,  .col8_1600,  .col9_1600,  .col10_1600, .col11_1600, .col12_1600,
	.col13_1600, .col14_1600, .col15_1600, .col16_1600, .col17_1600, .col18_1600,
	.col19_1600, .col20_1600, .col21_1600, .col22_1600, .col23_1600, .col24_1600{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 1500
@media #{$down1500}{
	@for $i from 1 through 24 {
		.col#{$i}_1500 { width: ($i / 24 * 100%); }
	}

	.col1_1500,  .col2_1500,  .col3_1500,  .col4_1500,  .col5_1500,  .col6_1500,
	.col7_1500,  .col8_1500,  .col9_1500,  .col10_1500, .col11_1500, .col12_1500,
	.col13_1500, .col14_1500, .col15_1500, .col16_1500, .col17_1500, .col18_1500,
	.col19_1500, .col20_1500, .col21_1500, .col22_1500, .col23_1500, .col24_1500{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 1400
@media #{$down1400}{
	@for $i from 1 through 24 {
		.col#{$i}_1400 { width: ($i / 24 * 100%); }
	}

	.col1_1400,  .col2_1400,  .col3_1400,  .col4_1400,  .col5_1400,  .col6_1400,
	.col7_1400,  .col8_1400,  .col9_1400,  .col10_1400, .col11_1400, .col12_1400,
	.col13_1400, .col14_1400, .col15_1400, .col16_1400, .col17_1400, .col18_1400,
	.col19_1400, .col20_1400, .col21_1400, .col22_1400, .col23_1400, .col24_1400{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 1300
@media #{$down1300}{
	@for $i from 1 through 24 {
		.col#{$i}_1300 { width: ($i / 24 * 100%); }
	}

	.col1_1300,  .col2_1300,  .col3_1300,  .col4_1300,  .col5_1300,  .col6_1300,
	.col7_1300,  .col8_1300,  .col9_1300,  .col10_1300, .col11_1300, .col12_1300,
	.col13_1300, .col14_1300, .col15_1300, .col16_1300, .col17_1300, .col18_1300,
	.col19_1300, .col20_1300, .col21_1300, .col22_1300, .col23_1300, .col24_1300{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 1200
@media #{$down1200}{
	@for $i from 1 through 24 {
		.col#{$i}_1200 { width: ($i / 24 * 100%); }
	}

	.col1_1200,  .col2_1200,  .col3_1200,  .col4_1200,  .col5_1200,  .col6_1200,
	.col7_1200,  .col8_1200,  .col9_1200,  .col10_1200, .col11_1200, .col12_1200,
	.col13_1200, .col14_1200, .col15_1200, .col16_1200, .col17_1200, .col18_1200,
	.col19_1200, .col20_1200, .col21_1200, .col22_1200, .col23_1200, .col24_1200{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 1100
@media #{$down1100}{
	@for $i from 1 through 24 {
		.col#{$i}_1100 { width: ($i / 24 * 100%); }
	}

	.col1_1100,  .col2_1100,  .col3_1100,  .col4_1100,  .col5_1100,  .col6_1100,
	.col7_1100,  .col8_1100,  .col9_1100,  .col10_1100, .col11_1100, .col12_1100,
	.col13_1100, .col14_1100, .col15_1100, .col16_1100, .col17_1100, .col18_1100,
	.col19_1100, .col20_1100, .col21_1100, .col22_1100, .col23_1100, .col24_1100{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 1024
@media #{$down1024}{
	@for $i from 1 through 24 {
		.col#{$i}_1024 { width: ($i / 24 * 100%); }
	}

	.col1_1024,  .col2_1024,  .col3_1024,  .col4_1024,  .col5_1024,  .col6_1024,
	.col7_1024,  .col8_1024,  .col9_1024,  .col10_1024, .col11_1024, .col12_1024,
	.col13_1024, .col14_1024, .col15_1024, .col16_1024, .col17_1024, .col18_1024,
	.col19_1024, .col20_1024, .col21_1024, .col22_1024, .col23_1024, .col24_1024{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 1000
@media #{$down1000}{
	@for $i from 1 through 24 {
		.col#{$i}_1000 { width: ($i / 24 * 100%); }
	}

	.col1_1000,  .col2_1000,  .col3_1000,  .col4_1000,  .col5_1000,  .col6_1000,
	.col7_1000,  .col8_1000,  .col9_1000,  .col10_1000, .col11_1000, .col12_1000,
	.col13_1000, .col14_1000, .col15_1000, .col16_1000, .col17_1000, .col18_1000,
	.col19_1000, .col20_1000, .col21_1000, .col22_1000, .col23_1000, .col24_1000{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 900
@media #{$down900}{
	@for $i from 1 through 24 {
		.col#{$i}_900 { width: ($i / 24 * 100%); }
	}

	.col1_900,  .col2_900,  .col3_900,  .col4_900,  .col5_900,  .col6_900,
	.col7_900,  .col8_900,  .col9_900,  .col10_900, .col11_900, .col12_900,
	.col13_900, .col14_900, .col15_900, .col16_900, .col17_900, .col18_900,
	.col19_900, .col20_900, .col21_900, .col22_900, .col23_900, .col24_900{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 800
@media #{$down800}{
	@for $i from 1 through 24 {
		.col#{$i}_800 { width: ($i / 24 * 100%); }
	}

	.col1_800,  .col2_800,  .col3_800,  .col4_800,  .col5_800,  .col6_800,
	.col7_800,  .col8_800,  .col9_800,  .col10_800, .col11_800, .col12_800,
	.col13_800, .col14_800, .col15_800, .col16_800, .col17_800, .col18_800,
	.col19_800, .col20_800, .col21_800, .col22_800, .col23_800, .col24_800{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 768
@media #{$down768}{
	@for $i from 1 through 24 {
		.col#{$i}_768 { width: ($i / 24 * 100%); }
	}

	.col1_768,  .col2_768,  .col3_768,  .col4_768,  .col5_768,  .col6_768,
	.col7_768,  .col8_768,  .col9_768,  .col10_768, .col11_768, .col12_768,
	.col13_768, .col14_768, .col15_768, .col16_768, .col17_768, .col18_768,
	.col19_768, .col20_768, .col21_768, .col22_768, .col23_768, .col24_768{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 700
@media #{$down700}{
	@for $i from 1 through 24 {
		.col#{$i}_700 { width: ($i / 24 * 100%); }
	}

	.col1_700,  .col2_700,  .col3_700,  .col4_700,  .col5_700,  .col6_700,
	.col7_700,  .col8_700,  .col9_700,  .col10_700, .col11_700, .col12_700,
	.col13_700, .col14_700, .col15_700, .col16_700, .col17_700, .col18_700,
	.col19_700, .col20_700, .col21_700, .col22_700, .col23_700, .col24_700{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 600
@media #{$down600}{
	@for $i from 1 through 24 {
		.col#{$i}_600 { width: ($i / 24 * 100%); }
	}

	.col1_600,  .col2_600,  .col3_600,  .col4_600,  .col5_600,  .col6_600,
	.col7_600,  .col8_600,  .col9_600,  .col10_600, .col11_600, .col12_600,
	.col13_600, .col14_600, .col15_600, .col16_600, .col17_600, .col18_600,
	.col19_600, .col20_600, .col21_600, .col22_600, .col23_600, .col24_600{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 500
@media #{$down500}{
	@for $i from 1 through 24 {
		.col#{$i}_500 { width: ($i / 24 * 100%); }
	}

	.col1_500,  .col2_500,  .col3_500,  .col4_500,  .col5_500,  .col6_500,
	.col7_500,  .col8_500,  .col9_500,  .col10_500, .col11_500, .col12_500,
	.col13_500, .col14_500, .col15_500, .col16_500, .col17_500, .col18_500,
	.col19_500, .col20_500, .col21_500, .col22_500, .col23_500, .col24_500{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 400
@media #{$down400}{
	@for $i from 1 through 24 {
		.col#{$i}_400 { width: ($i / 24 * 100%); }
	}

	.col1_400,  .col2_400,  .col3_400,  .col4_400,  .col5_400,  .col6_400,
	.col7_400,  .col8_400,  .col9_400,  .col10_400, .col11_400, .col12_400,
	.col13_400, .col14_400, .col15_400, .col16_400, .col17_400, .col18_400,
	.col19_400, .col20_400, .col21_400, .col22_400, .col23_400, .col24_400{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}

// Media query 300
@media #{$down300}{
	@for $i from 1 through 24 {
		.col#{$i}_300 { width: ($i / 24 * 100%); }
	}

	.col1_300,  .col2_300,  .col3_300,  .col4_300,  .col5_300,  .col6_300,
	.col7_300,  .col8_300,  .col9_300,  .col10_300, .col11_300, .col12_300,
	.col13_300, .col14_300, .col15_300, .col16_300, .col17_300, .col18_300,
	.col19_300, .col20_300, .col21_300, .col22_300, .col23_300, .col24_300{
		display: block; float: left; min-height: 1px; padding: 0 $gaps;
	}
}
