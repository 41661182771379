.menuOverlay{
    position: fixed;
    width: 100%;
    top: -100vh;
    z-index: 250;//240
    height: 100vh;
    background-color: $apfBlue;
    transition: top 0.8s $ease;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    @media #{$down1024} {
        padding-top: 120px;
        padding-bottom: 60px;
    }

    .mainMenu{
        & > ul{
            margin-top: -30px;
            @media #{$down1024} { margin-top: 0; }
            @media #{$down600}  { margin-top: -50px; }
            & > li{
                opacity: 0;
                top: -20px;
                position: relative;
                & > a{
                    display: block;
                    color: $menuColor;
                    font-family: $revolution;
                    font-size: 70px;
                    font-weight: bold;
                    letter-spacing: 0.02em;
                    text-transform: uppercase;
                    padding: 15px 0;
                    transition: color $ease 0.6s;
                    @media #{$down1300} { font-size: 40px; }
                    @media #{$down700} { font-size: 30px; }
                    @media #{$down400} { font-size: 25px; }
                    @media #{$down350} { padding: 10px 0;}

                    .number{
                        opacity: 0;
                        position: absolute;
                        left: -46px;
                        top: 22px;
                        font-weight: normal;
                        font-size: 21px;
                        transition: left $ease 0.6s, opacity $ease 0.8s;
                        @media #{$down1300} { top: 18px; }
                        @media #{$down700} { font-size: 16px; }
                    }
                }

                &:last-child{ padding-bottom: 0 };

                &.btnSubLvl1{
                    .subLvl1{
                        display: none;
                        li{
                            a{
                                color: $menuColor;
                                font-family: $revolution;
                                text-transform: uppercase;
                                letter-spacing: 0.02em;
                                font-size: 40px;
                                padding: 8px 0;
                                @media #{$down1300} { font-size: 30px; }
                                @media #{$down700} { font-size: 22px; }
                            }
                            &:first-child{
                                a{ padding-top: 30px;}
                                @media #{$down1300} { a{ padding-top: 10px;} }
                            }
                            &:last-child{
                                a{ padding-bottom: 60px;}
                                @media #{$down1300} { a{ padding-bottom: 40px;} }
                            }
                        }
                    }
                }

                &.active,
                &:hover{
                    padding-left: 46px;

                    @media #{$down1300}{ padding-left: 30px; }

                    @media #{$up300}{
                        & > a{
                            color: white;
                            .number{
                                opacity: 1;
                                left: 0;
                            }
                        }

                        &.btnSubLvl1{
                            .subLvl1{
                                li:hover{
                                    a{
                                        color: white;
                                    }
                                }
                            }
                        }
                    }
                }

                // Animations en cascade pour les éléments de menu
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}){
                        transition: padding $ease 0.8s, opacity $ease 0.6s ($i * 0.1s), top $ease 0.6s ($i * 0.1s);
                    }
                }

            }
        }
    }
}

.showMenuDropDown{

    .pageContainer {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            height: 71px;
            left: 0;
            right: 0;
            background: $apfBlue;
            z-index: 252;
        }
    }

    .menuOverlay{
        top: 0vh;
        //overflow: hidden;
        @media #{$down1024} {
            position: absolute;
            min-height: 100vh;
        }

        .mainMenu{
            & > ul{
                & > li{
                    opacity: 1;
                    top: 0;
                }
            }
        }
    }
}


.infos{
    color: white;
    font-size: 18px;
    line-height: 1.458333333333333em; // 26.25px / 18px
    padding-bottom: 60px;
    @media #{$down1024} { margin-top: 60px; }
    @media #{$down600}  { margin-top: 30px; }
    @media #{$down350} { font-size: 16px; padding-bottom: 30px; }

    .big{
        font-family: $revolution;
        font-size: 30px;
        letter-spacing: 0.025em;
        margin-bottom: 20px;

        @media #{$down350} { font-size: 22px; margin-bottom: 10px; }
        a{
            color: white;
        }
    }
}

.medias-sociaux-menu, .medias-sociaux-footer {
    display: flex;
    align-items: center;
    margin: 30px 0;

    .facebook-icon {
        width: 35px;
        height: 35px;
        margin-right: 1px;
        padding: 0 10px 5px 0;

        &:hover {
            opacity: 0.5;
        }
    }

    .linkedin-icon {
        width: 35px;
        height: 35px;
        margin-left: 10px;
        padding: 0 0 5px 10px;

        &:hover {
            opacity: 0.5;
        }
    }

    .amq-icon {
        margin-left: 30px;

        img{
            width: auto;
            height: 70px;
        }
    }
}

.medias-sociaux-footer {
    margin: 20px 0 0;

    .facebook-icon {
        width: 30px;
        height: 30px;
    }

    .linkedin-icon {
        width: 30px;
        height: 30px;
    }
}
