#page-equipement {

    .tableaux {
        margin-top: 215px;
    }

    table {
        border: 1px solid;
        font-family: $akkurat;
        margin-top: 25px;
        
        @media #{$down500} {
            display: block;
            overflow-x: auto;
            white-space: nowrap;
        }

        th {
            background-color: $apfDarkBlue;
            color: #fff;
            font-family: $revolution;
        }

        thead {
            tr th, tr td {
                padding: 20px;
                color: #fff;
                background: $color1;
                border: 1px solid $apfDarkBlue;
                font-weight: 700;
            }
        }

        tbody {
            tr td, tr th {
                padding: 12px;                 
                text-align: center !important;
                border: 1px solid $apfDarkBlue;
                vertical-align: middle !important;
                font: 14px/1.5 $font1; 
            }

            tr td {
                width: 32%;
                margin: 20px 0;
                font-size: 17px;
                line-height: 1.647058823529412em; // 28px / 17px
                color: $textColor;

                &:first-child {
                    width: 3%;
                }
            }
        }
		
		@media #{$down1400} {
            thead tr th, thead tr td, tbody tr td, tbody tr th { padding: 20px 10px; font: 14px/1.5 $font1; }
        }
        
		@media #{$down1200} {
            thead tr th, thead tr td, tbody tr td, tbody tr th { padding: 15px 7px; font: 13px/1.5 $font1; }
		}
		
		@media #{$down600} {
            thead tr th, thead tr td, tbody tr td, tbody tr th { padding: 10px 5px; font: 12px/1.5 $font1; }
		}
    }
}