.checkbox-field {
    position: relative;

    &__option-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &__option-label {
        display: flex;
        position: relative;
        margin-bottom: 15px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            display: inline;
            color: #2a3c47;
            box-shadow: inset 0px -1px 0 #2a3c47;
            transition: all 300ms;
        }

        a:hover {
            color: #fe6c4e;
            box-shadow: inset 0px -1px 0 #fe6c4e;
        }
    }

    &__option-text {
        flex: 1;
        font: 18px / 1.6 "MaisonNeue-Book", sans-serif;
        color: #2a3c47;
        order: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &__option-box {
        position: relative;
        width: 16px;
        height: 16px;
        margin-top: calc(((18px * 1.6) - 16px) / 2);
        margin-right: 12px;
        background-color: white;
        border: 1px solid #2a3c47;
        border-radius: 3px;
        order: 1;
        transition: background-color 300ms, border-color 300ms;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            width: 10px;
            height: 8px;
            background: url("../images/content/icons/checkmark.svg") no-repeat center/contain;
            transition: transform 300ms;
        }
    }

    &__option-input:checked ~ &__option-box {
        background-color: #67a7e7;
        border-color: #67a7e7;

        &::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }
}
