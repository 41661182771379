section.testimonials{
    color: white;

    .photoContainer{
        span{
            display: block;
            width: 100%;
            height: 525px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            @media #{$down768} { height: 300px; }
        }
    }

    .testimonialTexts{
        display: flex;
        height: 568px;
        align-items: center;
        @media #{$down1024} { height: auto; }
        .text{
            position: relative;
            p{
                color: white;
                font-size: 17px;
            }
            .author{
                text-transform: uppercase;
                letter-spacing: 0.1em;
                font-size: 12px;
                font-weight: bold;
                padding-left: 22px;
                position: relative;
                margin-top: 40px;
                &::before{
                    content: '';
                    width: 13px;
                    height: 2px;
                    background-color: white;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .images{
        position: relative;

        .testimonialImages{
            @media #{$down600} { padding-top: 70px; }
            .testimonialImage{
                width: 650px;
                height: 568px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                @media #{$down1600} { width: 465px; }
                @media #{$down768} { height: 360px; }
            }
        }
        .slick-slide {
            margin-right: 60px;
        }
        .arrows{
            position: absolute;
            height: 60px;
            width: 80px;
            bottom: -60px;
            left: 0;
            display: flex;
            @media #{$down1024} { left: 50%; transform: translateX(-50%) }
            .prec,
            .next{
                position: relative;
                width: 50%;
                cursor: pointer;
                transition: opacity $ease 0.6s;
                &:after{
                    content: '';
                    background-image: url(../images/content/icons/arrow_btn_white.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    position: absolute;
                    display: block;
                    width: 20px;
                    height: 10px;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover{
                    opacity: 0.6;
                }
            }
            .prec{
                &:after{
                    transform: translateY(-50%) rotate(180deg);
                    right: auto;
                }
            }
        }
    }

}

.bottomBlue{
    position: relative;
    &:after{
        content: '';
        background-color: $apfBlue;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 262px;
        z-index: -1;
        @media #{$down768} { top: 150px; }
    }

    .decoration{
        right: 0;
        top: 262px;
        bottom: 0;
        width: 270px;
        background-position: -5px -5px;
        @media #{$down768} { top: 150px; width: 149px; }
    }
}