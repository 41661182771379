//------------------\\
//--- Scrollfire ---\\
//------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce CSS a besoin du plugin BLK_scrollfire pour fonctionner, le css est appliquer sur les attribut data
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

$scrollfire: '[data-scrollfire], [data-multiscrollfire]';
$fire: '&[data-scrollfire="fire"], &[data-multiscrollfire="fire"]';

// LES ANIMATIONS __________
// Fade
[data-fadeup] {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 500ms, transform $duration $ease;

    #{$fire} { opacity: 1; transform: none; }
}


// LES DÉLAIS PENDANT LES CHARGEMENTS ET TRANSITIONS __________
.transition, .chargement {
    [data-fadeup] { opacity: 0 !important; transform: translateY(100px)  !important; }

    #{$scrollfire} {
        &[data-delay4] { transition-delay: 100ms; }
        &[data-delay3] { transition-delay: 200ms; }
        &[data-delay2] { transition-delay: 300ms; }
        &[data-delay1] { transition-delay: 400ms; }
        &[data-delay]  { transition-delay: 500ms; }
    }
}


// LES DÉLAIS APRÈS LES CHARGEMENTS ET TRANSITIONS __________
#{$scrollfire} {
	transition-delay: 0ms;
    &[data-delay]  { transition-delay: 100ms; }
    &[data-delay1] { transition-delay: 300ms; }
    &[data-delay2] { transition-delay: 500ms; }
    &[data-delay3] { transition-delay: 700ms; }
    &[data-delay4] { transition-delay: 900ms; }
}
