.homeSlider{
    background-color: black;
    position: relative;
    overflow-x: hidden;

    .homeSlides{
        margin-bottom: 0;
        .slide{
            min-height: 726px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;

            @media #{$down1300} { min-height: 580px; }
            @media #{$down1000} { min-height: 450px; }

            .slideContent{
                top: 50%;
                position: absolute;
                z-index: 2;
                transform: translateY(-50%);

                @media #{$down1000} { padding-top: 155px; }

                .overText,
                .text{
                    font-family: $revolution;
                    color: white;
                    text-transform: uppercase;
                }

                .overText{
                    font-size: 15px;
                    padding-bottom: 40px;
                    letter-spacing: 0.2em;
                    @media #{$down1000} { padding-bottom: 20px; }
                }
                .text{
                    font-size: 60px;
                    line-height: 1.064516129032258em; // 66px / 62px
                    letter-spacing: 0.02em;
                    @media #{$down350} { font-size: 45px ; }
                    span{
                        display: block;
                        margin-left: 56px;
                        @media #{$down400} { margin-left: 30px ; }
                    }
                }
                a.btn{
                    margin-top: 80px;
                    @media #{$down1000} { margin-top: 40px; }
                }
            }
        }
    }

    .slick-dots{
        top: 50%;
        bottom: auto;
        left: 0;
        width: 56px;
        z-index: 200;
        transform: translateY(-50%);

        @media #{$down1024} {
            top: auto;
            transform: rotate(-90deg) translateY(-50%);
            bottom: 0;
            left: 50%;
            width: auto;
            height: 40px;
        }

        li{
            display: block;
            margin: 0;
            height: auto;
            width: 56px;
            @media #{$down1024} { width: 40px; }
            button{
                padding: 5px 0;
                &:before{
                    content: "";
                    background-color: white;
                    width: 22px;
                    height: 2px;
                    display: block;
                    transition: all $ease 0.6s;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &.slick-active{
                button{
                    &:before{
                        width: 56px;
                        @media #{$down1024} { width: 40px; }
                    }
                }
            }
        }
    }

    .decoration{
        background-position: 0 6px;
        width: 270px;
        left: 0;
        height: 100%;
        bottom: 0;
        top: 0;
        background-image: url(../images/content/bg/grid_light.png);
        opacity: 0.3;
        @media #{$down768} { width: 149px; }
    }
}