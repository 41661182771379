select {
    display: block;
    width: 100%;
    opacity: 0;
}

.chosen-container {
    position: relative;
    display: inline-block;
    width: 100% !important;
    font-size: 14px;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chosen-container .chosen-single {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 0 0 20px;
    border: none;
    font: 15px/60px $font1;
    color: $color2;
    cursor: pointer;
    white-space: nowrap;
    background-color: #fff;
    overflow: hidden;
	padding-right: 60px;
    z-index: 49;
	box-shadow: 3px 3px 10px rgba($color2, 0.1);
	transition: padding 400ms, box-shadow 300ms;
}

.chosen-container .chosen-default {
    font: 15px/60px $font1;
    color: $color2;
	letter-spacing: 1px;
}

.chosen-container-active {
    z-index: 50;
}

.chosen-container-active .chosen-single {
}

.chosen-container .chosen-single span {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.chosen-container .chosen-single div {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 60px;
    height: 100%;
    background: url('../images/content/icons/pointer.svg') no-repeat center center;
    background-size: 12px auto;
    transition: transform 500ms;
    -webkit-transition: -webkit-transform 500ms;
    -webkit-backface-visibility: visible;
    transform: rotate(90deg);
}

.chosen-container .chosen-single div b {
    display: none;
}

.chosen-container-active.chosen-with-drop .chosen-single {
    box-shadow: none;
	padding-left: 20px;
}

.chosen-container-active.chosen-with-drop .chosen-single div {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    transform: rotate(-90deg);
}

.chosen-container .chosen-drop {
    position: absolute;
    top: 0;
    width: 100%;
    border: none;
    border-top: none;
    background: #fff;
    visibility: hidden;
    z-index: 48;
	box-shadow: 3px 3px 10px rgba($color2, 0.1);
    opacity: 0;
    transition: padding-top 500ms, opacity 500ms, box-shadow 500ms, visibility 0ms ease 500ms;
}

.chosen-container.chosen-with-drop .chosen-drop {
    top: 0;
	padding-top: 60px;
	border-top: solid 1px rgba($color2, 0.3);
    visibility: visible;	
    opacity: 1;
	transition: padding 400ms, opacity 400ms;
}

.chosen-container .chosen-results {
    position: relative;
    max-height: 204px;
	overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.chosen-container .chosen-results li {
    display: block !important;
    margin: 0;
    padding: 12px 20px;
    font: 15px/20px $font1;
    list-style: none;
	word-wrap: break-word;
    -webkit-touch-callout: none;
	transition: color 300ms;
}

.chosen-container .chosen-results li:first-child {
    padding-top: 25px;
	border-top: solid 1px rgba($color2, 0.3);
}

.chosen-container .chosen-results li:last-child {
    padding-bottom: 25px;
}

.chosen-container .chosen-results li.active-result {

    cursor: pointer;
    color: $color2;
}

.chosen-container .chosen-results li:hover {
    color: $apfLightBlue;
}

.chosen-container .chosen-results li.result-selected {
    font-family: $font1;
	cursor: default;
	color: $apfBlue !important;
}

.chosen-container .chosen-results li.disabled-result {
    display: list-item;
    color: rgba($color2, 0.4);
    cursor: default;
}

.chosen-disabled {
    opacity: 0.35 !important;
    cursor: default;
}

.chosen-disabled .chosen-single {
    cursor: default;
}

.chosen-container-single .chosen-search {
    display: none;
}

// ON FORM
#formZone .chosen-container .chosen-single 									{ padding-left: 0; border-bottom: solid 1px rgba($color2, 0.3); box-shadow: 0 0 0 rgba($color2, 0); }
#formZone .chosen-container-active.chosen-with-drop .chosen-single			{ padding-left: 20px; border-color: #e5e5e5; }
#formZone select 															{ padding-left: 0; border-bottom: solid 1px rgba($color2, 0.3); box-shadow: 0 0 0 rgba($color2, 0); }

// INTOUCH, les deux premiers on peut mixer
select {
    position: relative;
    display: block;
    font: 15px/60px $font1;
    padding: 0 60px 0 20px;
    height: 60px;
    background: #fff;
    opacity: 1;
    color: $color2;
	width: 100%;
    box-shadow: 3px 3px 15px rgba($color2, 0);
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
select::-ms-expand {
    display: none;
}

.select-field {
    position: absolute;
    float: left;
    top: 250px;
    right: 160px;
    width: 320px;
    max-width: 100%;
    padding: 0;
	&:after {
		position: absolute;
		right: 0;
		top: 0;
		width: 50px;
		height: 60px;
		content: '';
		cursor: pointer;
		pointer-events: none;
		background: url('../../images/icones/select-arrow.png') no-repeat center center;
		background-size: 12px auto;
	}
	@media #{$down1000}		{ width: 250px; }
	@media #{$down768}		{ width: 300px; }
}