section.qualifiedEmployees{
    position: relative;

    .decorationImage{
        width: 33.33333333%;
        position: absolute;
        top: -150px;
        bottom: 86px;
        right: 0;
        z-index: 3;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media #{$down1100} { top: -80px; }
        @media #{$down768} { display: none; }
    }
}

section.ourExpertise{
    position: relative;

    @media #{$down600} {
        h2.light, a.btn.light{ color: $apfDarkBlue; }
    }

    .row {
        .sectionTitle {
            @media #{$down600} {
                padding: 0 0 50px 0;
            }
        }
    }

    .textContainer{
        @media #{$down600} {
            padding: 30px 0 70px 0;
        }
    }

    .expertises{
        .expertise{
            @media #{$down768} {
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            a{
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                display: block;
                .expertiseContent{
                    padding: 320px 30px 60px 30px;
                    text-align: center;
                    transition: all $ease 0.6s;
                    @media #{$down768} {
                        padding-top: 150px;
                    }

                    .number{
                        font-size: 78px;
                        font-weight: bold;
                        color: white;
                        line-height: 1em;
                        font-family: $revolution;
                        margin-bottom: 30px;
                    }

                    h3{
                        font-weight: normal;
                        margin-top: 0;
                        @media #{$down900} { font-size: 25px; }
                    }
                }
                &:hover{
                    .expertiseContent{
                        background-color: rgba($apfDarkBlue, 0.6)
                    }
                }
            }
        }
    }

    .decoration1{
        background-position: 6px 6px;
        width: 50%;
        right: 0;
        height: 276px;
        bottom: 0;
    }

    .decoration2{
        background-position: 0px 6px;
        width: 270px;
        left: 0;
        height: 10000px;
        bottom: 140px;
        @media #{$down768} { width: 149px; }
    }

    &:after{
        content: '';
        position: absolute;
        z-index: -1;
        background-color: $apfBlue;
        display: block;
        width: 50%;
        left: 0;
        top: 0;
        bottom: 140px;

        @media #{$down600} { top: auto; height: 1110px; bottom: 50px; }
    }
}

section.difference{
    & > div{
        h5,h2{
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.bottomGrid{
    position: relative;
    .decoration3{
        background-position: -5px 6px;
        width: 270px;
        bottom: 0;
        top: 80px;
        right: 0;
        @media #{$down768} { width: 149px; }
    }
}

section.photo{
    .photoContainer{
        width: 100%;
        padding-bottom: 45%;
        background-color: $apfDarkBlue;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media #{$down768} { height: 300px; }
    }
}

section.jobs{
    .images{
        position: relative;
        .jobTexts{
            .text{
                @media #{$down768} { min-height: 550px; }
            }
        }
        .jobImages{
            .jobImage{
                width: 650px;
                height: 568px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                @media #{$down1600} { width: 465px; }
                @media #{$down768} { height: 360px; }
            }
        }
        .slick-slide {
            margin-right: 60px;
        }
        .arrows{
            position: absolute;
            height: 60px;
            width: 80px;
            bottom: -60px;
            left: 0;
            display: flex;
            @media #{$down1024} { left: 50%; transform: translateX(-50%) }
            .prec,
            .next{
                position: relative;
                width: 50%;
                cursor: pointer;
                transition: opacity $ease 0.6s;
                &:after{
                    content: '';
                    background-image: url(../images/content/icons/arrow_btn.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    position: absolute;
                    display: block;
                    width: 20px;
                    height: 10px;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover{
                    opacity: 0.6;
                }
            }
            .prec{
                &:after{
                    transform: translateY(-50%) rotate(180deg);
                    right: auto;
                }
            }
        }
    }
}