// UN CHAMP DE TEXTE __________
.input-field {
	input {
		width: 100%;
		height: 45px;
		padding: 0px 0;
		border: none;
		border-bottom: 2px solid $apfDarkBlue;
		font: 18px/45px $akkurat;
		color: $apfDarkBlue;
		background: transparent;
		transition: 300ms ease;
		padding-bottom: 1px;
		-webkit-appearance: none;
		border-radius: 0;

		&:focus {
			border-bottom-color: $apfBlue;
			border-bottom-width: 3px;
			padding-bottom: 0;
		}
		&.valid { border-bottom: 3px solid $apfBlue; padding-bottom: 0; }
	}

	label {
		position: absolute;
		top: 0px;
        left: 0;
		font: 18px/45px $akkurat;
		color: $apfDarkBlue;
		cursor: text;
		transition: 300ms $ease;
	}

	input{
		&:focus,
		&.valid,
		&.error,
		&.notEmpty{
			& + label {
				top: -12px;
				font-size: 11px;
				line-height: 12px;
				cursor: default;
				pointer-events: none;
				color: #b1b1b1;
			}
		}
		&.error{
			border-color: $apfOrange;
		}
	}

	input.vide + label {
		top: 0px;
		font: 16px/45px $akkurat;
	}
}
