header {
    .topBar{
        position: absolute;
        z-index: 300;
        width: 100%;
        .left{
            .logo{
                padding: 16px 40px;
                position: relative;
                z-index: 250;
                @media #{$down1024} { padding: 16px 30px; }
                @media #{$down768} { padding: 16px 20px; }
                svg{
                    width: 152px;
                    height: 44px;
                    @media #{$down350} { width: 110px; height: 32px; }
                    path,polygon,rect{
                        fill: white;
                    }
                }
            }
        }
        .right{
            position: absolute;
            top: 0;
            right: 0;
            ul{
                display: flex;
                overflow: hidden;
                li{
                    z-index: 250;
                    height: 100%;
                    a{
                        padding: 30px;
                        display: block;
                        text-transform: uppercase;
                        cursor: pointer;
                        color: white;
                        font-weight: bold;
                        font-size: 12px;
                        letter-spacing: 0.1em;
                        transition: opacity 0.6s $ease;
                        height: 100%;
                        &:hover{
                            opacity: 0.5;
                        }
                    }

                    @media #{$down500} {
                        &.langSwitcher{
                            a{
                                padding-top: 24px;
                                padding-bottom: 24px;
                            }
                        }
                    }

                    &.quote{
                        background-color: white;
                        transition: width $ease 0.6s;
                        width: 240px;
                        text-align: center;
                        a{
                            color: $apfDarkBlue;
                            white-space: nowrap;
                        }
                        @media #{$down768} { width: 0; display: none; }
                    }
                    &.menuToggle{
                        background-color: $apfBlue;
                        position: relative;

                        a{
                            padding-right: 75px;

                            @media #{$down500} {
                                padding-left: 0;
                                padding-right: 0;
                                width: 82px;
                                .text{
                                    display: none;
                                }
                            }

                            .hamburger{
                                width: 24px;
                                height: 16px;
                                display: block;
                                position: absolute;
                                right: 30px;
                                top: 50%;
                                transform: translateY(-50%);
                                &:before,
                                .center,
                                .center:after,
                                &:after{
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    height: 2px;
                                    background-color: white;
                                    display: block;
                                    opacity: 1;
                                    transition: all 0.6s $ease;
                                }
                                &:before{
                                    top: 0;
                                }
                                .center{
                                    top: 50%;
                                    width: 65%;
                                    margin-left: 17.5%;
                                    transform: translateY(-50%);
                                }
                                &:after{
                                    bottom: 0;
                                }

                                &.open{
                                    &:before,
                                    &:after{
                                        opacity: 0;
                                    }
                                    .center{
                                        transform: translateY(-50%) rotate(45deg);
                                        &:after{
                                            transform: rotate(90deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Gestion du changement du bouton hamburger pour le toggle de menu
.showMenuDropDown{
    header{
        .topBar{
            .right{
                ul{
                    li.quote{
                        width: 0;
                        display: none;
                        a {
                            display: none;
                        }
                    }
                    li.menuToggle{
                        a{
                            .hamburger{
                                &:before,
                                &:after{
                                    opacity: 0;
                                }
                                .center{
                                    transform: translateY(-50%) rotate(45deg);
                                    &:after{
                                        transform: rotate(90deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
