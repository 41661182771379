section.mainText{
    .contactCol{
        width: 33.33333333%;
        position: absolute;
        top: -100px;
        left: 0;
        z-index: 3;
        color: white;

        @media #{$down1300} { top: -50px; }
        @media #{$down1024} {
            top: auto;
            width: 100%;
            position: static;
        }

        h3{
            background-color: rgba(0,0,0,0.2);
            line-height: 100px;
            margin-bottom: 0;
        }

        .coordonneesContainer{
            background-color: $apfBlue;
            h5,p,a{
                color: white;
            }
            h5{
                font-size: 12px;
                margin-bottom: 20px;
            }
            .big{
                font-size: 25px;
                line-height: 1.4em; //35px / 25px
            }
            a{
                font-weight: bold;
            }

            &--white {
                background-color: transparent;
                h5,p,a{
                    color: $apfDarkBlue;
                }

                @media #{$down1024} { padding-bottom: 0; };
            }
        }

    }

    .rightContent{
        @media #{$down1024} { padding-left: 30px; padding-top: 70px };
        @media #{$down768} { padding-left: 20px; };
    }
}