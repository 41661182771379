@charset "UTF-8";
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img,
ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time,
mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

html {
  overflow-x: hidden;
}

body {
  line-height: 1;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

strong, b {
  font-weight: bold;
}

input:focus {
  outline: none;
}

blockquote, q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a[ng-click], div[ng-click], p[ng-click], span[ng-click], img[ng-click], a[ui-sref], div[ui-sref], p[ui-sref], span[ui-sref], img[ui-sref] {
  cursor: pointer;
}

textarea:hover, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active, button:hover, label:focus, .btn:active, .btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
}

.slick-slide, .slick-slide a, .slick-slide:focus {
  outline: none !important;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill, textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

a {
  -webkit-tap-highlight-color: transparent;
}

.row.fixPadding {
  width: calc(100% + 1% + 1%);
  margin-left: -1%;
  margin-right: -1%;
}
.row:after {
  content: "";
  display: block;
  clear: both;
}

.col1 {
  width: 4.1666666667%;
}

.col2 {
  width: 8.3333333333%;
}

.col3 {
  width: 12.5%;
}

.col4 {
  width: 16.6666666667%;
}

.col5 {
  width: 20.8333333333%;
}

.col6 {
  width: 25%;
}

.col7 {
  width: 29.1666666667%;
}

.col8 {
  width: 33.3333333333%;
}

.col9 {
  width: 37.5%;
}

.col10 {
  width: 41.6666666667%;
}

.col11 {
  width: 45.8333333333%;
}

.col12 {
  width: 50%;
}

.col13 {
  width: 54.1666666667%;
}

.col14 {
  width: 58.3333333333%;
}

.col15 {
  width: 62.5%;
}

.col16 {
  width: 66.6666666667%;
}

.col17 {
  width: 70.8333333333%;
}

.col18 {
  width: 75%;
}

.col19 {
  width: 79.1666666667%;
}

.col20 {
  width: 83.3333333333%;
}

.col21 {
  width: 87.5%;
}

.col22 {
  width: 91.6666666667%;
}

.col23 {
  width: 95.8333333333%;
}

.col24 {
  width: 100%;
}

.col, .col1, .col2, .col3, .col4, .col5, .col6, .col7, .col8, .col9, .col10, .col11, .col12,
.col13, .col14, .col15, .col16, .col17, .col18, .col19, .col20, .col21, .col22, .col23, .col24 {
  display: block;
  float: left;
  min-height: 1px;
  padding: 0 1%;
}

@media screen and (max-width: 1800px) {
  .col1_1800 {
    width: 4.1666666667%;
  }
  .col2_1800 {
    width: 8.3333333333%;
  }
  .col3_1800 {
    width: 12.5%;
  }
  .col4_1800 {
    width: 16.6666666667%;
  }
  .col5_1800 {
    width: 20.8333333333%;
  }
  .col6_1800 {
    width: 25%;
  }
  .col7_1800 {
    width: 29.1666666667%;
  }
  .col8_1800 {
    width: 33.3333333333%;
  }
  .col9_1800 {
    width: 37.5%;
  }
  .col10_1800 {
    width: 41.6666666667%;
  }
  .col11_1800 {
    width: 45.8333333333%;
  }
  .col12_1800 {
    width: 50%;
  }
  .col13_1800 {
    width: 54.1666666667%;
  }
  .col14_1800 {
    width: 58.3333333333%;
  }
  .col15_1800 {
    width: 62.5%;
  }
  .col16_1800 {
    width: 66.6666666667%;
  }
  .col17_1800 {
    width: 70.8333333333%;
  }
  .col18_1800 {
    width: 75%;
  }
  .col19_1800 {
    width: 79.1666666667%;
  }
  .col20_1800 {
    width: 83.3333333333%;
  }
  .col21_1800 {
    width: 87.5%;
  }
  .col22_1800 {
    width: 91.6666666667%;
  }
  .col23_1800 {
    width: 95.8333333333%;
  }
  .col24_1800 {
    width: 100%;
  }
  .col1_1800, .col2_1800, .col3_1800, .col4_1800, .col5_1800, .col6_1800,
  .col7_1800, .col8_1800, .col9_1800, .col10_1800, .col11_1800, .col12_1800,
  .col13_1800, .col14_1800, .col15_1800, .col16_1800, .col17_1800, .col18_1800,
  .col19_1800, .col20_1800, .col21_1800, .col22_1800, .col23_1800, .col24_1800 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1700px) {
  .col1_1700 {
    width: 4.1666666667%;
  }
  .col2_1700 {
    width: 8.3333333333%;
  }
  .col3_1700 {
    width: 12.5%;
  }
  .col4_1700 {
    width: 16.6666666667%;
  }
  .col5_1700 {
    width: 20.8333333333%;
  }
  .col6_1700 {
    width: 25%;
  }
  .col7_1700 {
    width: 29.1666666667%;
  }
  .col8_1700 {
    width: 33.3333333333%;
  }
  .col9_1700 {
    width: 37.5%;
  }
  .col10_1700 {
    width: 41.6666666667%;
  }
  .col11_1700 {
    width: 45.8333333333%;
  }
  .col12_1700 {
    width: 50%;
  }
  .col13_1700 {
    width: 54.1666666667%;
  }
  .col14_1700 {
    width: 58.3333333333%;
  }
  .col15_1700 {
    width: 62.5%;
  }
  .col16_1700 {
    width: 66.6666666667%;
  }
  .col17_1700 {
    width: 70.8333333333%;
  }
  .col18_1700 {
    width: 75%;
  }
  .col19_1700 {
    width: 79.1666666667%;
  }
  .col20_1700 {
    width: 83.3333333333%;
  }
  .col21_1700 {
    width: 87.5%;
  }
  .col22_1700 {
    width: 91.6666666667%;
  }
  .col23_1700 {
    width: 95.8333333333%;
  }
  .col24_1700 {
    width: 100%;
  }
  .col1_1700, .col2_1700, .col3_1700, .col4_1700, .col5_1700, .col6_1700,
  .col7_1700, .col8_1700, .col9_1700, .col10_1700, .col11_1700, .col12_1700,
  .col13_1700, .col14_1700, .col15_1700, .col16_1700, .col17_1700, .col18_1700,
  .col19_1700, .col20_1700, .col21_1700, .col22_1700, .col23_1700, .col24_1700 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1600px) {
  .col1_1600 {
    width: 4.1666666667%;
  }
  .col2_1600 {
    width: 8.3333333333%;
  }
  .col3_1600 {
    width: 12.5%;
  }
  .col4_1600 {
    width: 16.6666666667%;
  }
  .col5_1600 {
    width: 20.8333333333%;
  }
  .col6_1600 {
    width: 25%;
  }
  .col7_1600 {
    width: 29.1666666667%;
  }
  .col8_1600 {
    width: 33.3333333333%;
  }
  .col9_1600 {
    width: 37.5%;
  }
  .col10_1600 {
    width: 41.6666666667%;
  }
  .col11_1600 {
    width: 45.8333333333%;
  }
  .col12_1600 {
    width: 50%;
  }
  .col13_1600 {
    width: 54.1666666667%;
  }
  .col14_1600 {
    width: 58.3333333333%;
  }
  .col15_1600 {
    width: 62.5%;
  }
  .col16_1600 {
    width: 66.6666666667%;
  }
  .col17_1600 {
    width: 70.8333333333%;
  }
  .col18_1600 {
    width: 75%;
  }
  .col19_1600 {
    width: 79.1666666667%;
  }
  .col20_1600 {
    width: 83.3333333333%;
  }
  .col21_1600 {
    width: 87.5%;
  }
  .col22_1600 {
    width: 91.6666666667%;
  }
  .col23_1600 {
    width: 95.8333333333%;
  }
  .col24_1600 {
    width: 100%;
  }
  .col1_1600, .col2_1600, .col3_1600, .col4_1600, .col5_1600, .col6_1600,
  .col7_1600, .col8_1600, .col9_1600, .col10_1600, .col11_1600, .col12_1600,
  .col13_1600, .col14_1600, .col15_1600, .col16_1600, .col17_1600, .col18_1600,
  .col19_1600, .col20_1600, .col21_1600, .col22_1600, .col23_1600, .col24_1600 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1500px) {
  .col1_1500 {
    width: 4.1666666667%;
  }
  .col2_1500 {
    width: 8.3333333333%;
  }
  .col3_1500 {
    width: 12.5%;
  }
  .col4_1500 {
    width: 16.6666666667%;
  }
  .col5_1500 {
    width: 20.8333333333%;
  }
  .col6_1500 {
    width: 25%;
  }
  .col7_1500 {
    width: 29.1666666667%;
  }
  .col8_1500 {
    width: 33.3333333333%;
  }
  .col9_1500 {
    width: 37.5%;
  }
  .col10_1500 {
    width: 41.6666666667%;
  }
  .col11_1500 {
    width: 45.8333333333%;
  }
  .col12_1500 {
    width: 50%;
  }
  .col13_1500 {
    width: 54.1666666667%;
  }
  .col14_1500 {
    width: 58.3333333333%;
  }
  .col15_1500 {
    width: 62.5%;
  }
  .col16_1500 {
    width: 66.6666666667%;
  }
  .col17_1500 {
    width: 70.8333333333%;
  }
  .col18_1500 {
    width: 75%;
  }
  .col19_1500 {
    width: 79.1666666667%;
  }
  .col20_1500 {
    width: 83.3333333333%;
  }
  .col21_1500 {
    width: 87.5%;
  }
  .col22_1500 {
    width: 91.6666666667%;
  }
  .col23_1500 {
    width: 95.8333333333%;
  }
  .col24_1500 {
    width: 100%;
  }
  .col1_1500, .col2_1500, .col3_1500, .col4_1500, .col5_1500, .col6_1500,
  .col7_1500, .col8_1500, .col9_1500, .col10_1500, .col11_1500, .col12_1500,
  .col13_1500, .col14_1500, .col15_1500, .col16_1500, .col17_1500, .col18_1500,
  .col19_1500, .col20_1500, .col21_1500, .col22_1500, .col23_1500, .col24_1500 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1400px) {
  .col1_1400 {
    width: 4.1666666667%;
  }
  .col2_1400 {
    width: 8.3333333333%;
  }
  .col3_1400 {
    width: 12.5%;
  }
  .col4_1400 {
    width: 16.6666666667%;
  }
  .col5_1400 {
    width: 20.8333333333%;
  }
  .col6_1400 {
    width: 25%;
  }
  .col7_1400 {
    width: 29.1666666667%;
  }
  .col8_1400 {
    width: 33.3333333333%;
  }
  .col9_1400 {
    width: 37.5%;
  }
  .col10_1400 {
    width: 41.6666666667%;
  }
  .col11_1400 {
    width: 45.8333333333%;
  }
  .col12_1400 {
    width: 50%;
  }
  .col13_1400 {
    width: 54.1666666667%;
  }
  .col14_1400 {
    width: 58.3333333333%;
  }
  .col15_1400 {
    width: 62.5%;
  }
  .col16_1400 {
    width: 66.6666666667%;
  }
  .col17_1400 {
    width: 70.8333333333%;
  }
  .col18_1400 {
    width: 75%;
  }
  .col19_1400 {
    width: 79.1666666667%;
  }
  .col20_1400 {
    width: 83.3333333333%;
  }
  .col21_1400 {
    width: 87.5%;
  }
  .col22_1400 {
    width: 91.6666666667%;
  }
  .col23_1400 {
    width: 95.8333333333%;
  }
  .col24_1400 {
    width: 100%;
  }
  .col1_1400, .col2_1400, .col3_1400, .col4_1400, .col5_1400, .col6_1400,
  .col7_1400, .col8_1400, .col9_1400, .col10_1400, .col11_1400, .col12_1400,
  .col13_1400, .col14_1400, .col15_1400, .col16_1400, .col17_1400, .col18_1400,
  .col19_1400, .col20_1400, .col21_1400, .col22_1400, .col23_1400, .col24_1400 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1300px) {
  .col1_1300 {
    width: 4.1666666667%;
  }
  .col2_1300 {
    width: 8.3333333333%;
  }
  .col3_1300 {
    width: 12.5%;
  }
  .col4_1300 {
    width: 16.6666666667%;
  }
  .col5_1300 {
    width: 20.8333333333%;
  }
  .col6_1300 {
    width: 25%;
  }
  .col7_1300 {
    width: 29.1666666667%;
  }
  .col8_1300 {
    width: 33.3333333333%;
  }
  .col9_1300 {
    width: 37.5%;
  }
  .col10_1300 {
    width: 41.6666666667%;
  }
  .col11_1300 {
    width: 45.8333333333%;
  }
  .col12_1300 {
    width: 50%;
  }
  .col13_1300 {
    width: 54.1666666667%;
  }
  .col14_1300 {
    width: 58.3333333333%;
  }
  .col15_1300 {
    width: 62.5%;
  }
  .col16_1300 {
    width: 66.6666666667%;
  }
  .col17_1300 {
    width: 70.8333333333%;
  }
  .col18_1300 {
    width: 75%;
  }
  .col19_1300 {
    width: 79.1666666667%;
  }
  .col20_1300 {
    width: 83.3333333333%;
  }
  .col21_1300 {
    width: 87.5%;
  }
  .col22_1300 {
    width: 91.6666666667%;
  }
  .col23_1300 {
    width: 95.8333333333%;
  }
  .col24_1300 {
    width: 100%;
  }
  .col1_1300, .col2_1300, .col3_1300, .col4_1300, .col5_1300, .col6_1300,
  .col7_1300, .col8_1300, .col9_1300, .col10_1300, .col11_1300, .col12_1300,
  .col13_1300, .col14_1300, .col15_1300, .col16_1300, .col17_1300, .col18_1300,
  .col19_1300, .col20_1300, .col21_1300, .col22_1300, .col23_1300, .col24_1300 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1200px) {
  .col1_1200 {
    width: 4.1666666667%;
  }
  .col2_1200 {
    width: 8.3333333333%;
  }
  .col3_1200 {
    width: 12.5%;
  }
  .col4_1200 {
    width: 16.6666666667%;
  }
  .col5_1200 {
    width: 20.8333333333%;
  }
  .col6_1200 {
    width: 25%;
  }
  .col7_1200 {
    width: 29.1666666667%;
  }
  .col8_1200 {
    width: 33.3333333333%;
  }
  .col9_1200 {
    width: 37.5%;
  }
  .col10_1200 {
    width: 41.6666666667%;
  }
  .col11_1200 {
    width: 45.8333333333%;
  }
  .col12_1200 {
    width: 50%;
  }
  .col13_1200 {
    width: 54.1666666667%;
  }
  .col14_1200 {
    width: 58.3333333333%;
  }
  .col15_1200 {
    width: 62.5%;
  }
  .col16_1200 {
    width: 66.6666666667%;
  }
  .col17_1200 {
    width: 70.8333333333%;
  }
  .col18_1200 {
    width: 75%;
  }
  .col19_1200 {
    width: 79.1666666667%;
  }
  .col20_1200 {
    width: 83.3333333333%;
  }
  .col21_1200 {
    width: 87.5%;
  }
  .col22_1200 {
    width: 91.6666666667%;
  }
  .col23_1200 {
    width: 95.8333333333%;
  }
  .col24_1200 {
    width: 100%;
  }
  .col1_1200, .col2_1200, .col3_1200, .col4_1200, .col5_1200, .col6_1200,
  .col7_1200, .col8_1200, .col9_1200, .col10_1200, .col11_1200, .col12_1200,
  .col13_1200, .col14_1200, .col15_1200, .col16_1200, .col17_1200, .col18_1200,
  .col19_1200, .col20_1200, .col21_1200, .col22_1200, .col23_1200, .col24_1200 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1100px) {
  .col1_1100 {
    width: 4.1666666667%;
  }
  .col2_1100 {
    width: 8.3333333333%;
  }
  .col3_1100 {
    width: 12.5%;
  }
  .col4_1100 {
    width: 16.6666666667%;
  }
  .col5_1100 {
    width: 20.8333333333%;
  }
  .col6_1100 {
    width: 25%;
  }
  .col7_1100 {
    width: 29.1666666667%;
  }
  .col8_1100 {
    width: 33.3333333333%;
  }
  .col9_1100 {
    width: 37.5%;
  }
  .col10_1100 {
    width: 41.6666666667%;
  }
  .col11_1100 {
    width: 45.8333333333%;
  }
  .col12_1100 {
    width: 50%;
  }
  .col13_1100 {
    width: 54.1666666667%;
  }
  .col14_1100 {
    width: 58.3333333333%;
  }
  .col15_1100 {
    width: 62.5%;
  }
  .col16_1100 {
    width: 66.6666666667%;
  }
  .col17_1100 {
    width: 70.8333333333%;
  }
  .col18_1100 {
    width: 75%;
  }
  .col19_1100 {
    width: 79.1666666667%;
  }
  .col20_1100 {
    width: 83.3333333333%;
  }
  .col21_1100 {
    width: 87.5%;
  }
  .col22_1100 {
    width: 91.6666666667%;
  }
  .col23_1100 {
    width: 95.8333333333%;
  }
  .col24_1100 {
    width: 100%;
  }
  .col1_1100, .col2_1100, .col3_1100, .col4_1100, .col5_1100, .col6_1100,
  .col7_1100, .col8_1100, .col9_1100, .col10_1100, .col11_1100, .col12_1100,
  .col13_1100, .col14_1100, .col15_1100, .col16_1100, .col17_1100, .col18_1100,
  .col19_1100, .col20_1100, .col21_1100, .col22_1100, .col23_1100, .col24_1100 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1024px) {
  .col1_1024 {
    width: 4.1666666667%;
  }
  .col2_1024 {
    width: 8.3333333333%;
  }
  .col3_1024 {
    width: 12.5%;
  }
  .col4_1024 {
    width: 16.6666666667%;
  }
  .col5_1024 {
    width: 20.8333333333%;
  }
  .col6_1024 {
    width: 25%;
  }
  .col7_1024 {
    width: 29.1666666667%;
  }
  .col8_1024 {
    width: 33.3333333333%;
  }
  .col9_1024 {
    width: 37.5%;
  }
  .col10_1024 {
    width: 41.6666666667%;
  }
  .col11_1024 {
    width: 45.8333333333%;
  }
  .col12_1024 {
    width: 50%;
  }
  .col13_1024 {
    width: 54.1666666667%;
  }
  .col14_1024 {
    width: 58.3333333333%;
  }
  .col15_1024 {
    width: 62.5%;
  }
  .col16_1024 {
    width: 66.6666666667%;
  }
  .col17_1024 {
    width: 70.8333333333%;
  }
  .col18_1024 {
    width: 75%;
  }
  .col19_1024 {
    width: 79.1666666667%;
  }
  .col20_1024 {
    width: 83.3333333333%;
  }
  .col21_1024 {
    width: 87.5%;
  }
  .col22_1024 {
    width: 91.6666666667%;
  }
  .col23_1024 {
    width: 95.8333333333%;
  }
  .col24_1024 {
    width: 100%;
  }
  .col1_1024, .col2_1024, .col3_1024, .col4_1024, .col5_1024, .col6_1024,
  .col7_1024, .col8_1024, .col9_1024, .col10_1024, .col11_1024, .col12_1024,
  .col13_1024, .col14_1024, .col15_1024, .col16_1024, .col17_1024, .col18_1024,
  .col19_1024, .col20_1024, .col21_1024, .col22_1024, .col23_1024, .col24_1024 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 1000px) {
  .col1_1000 {
    width: 4.1666666667%;
  }
  .col2_1000 {
    width: 8.3333333333%;
  }
  .col3_1000 {
    width: 12.5%;
  }
  .col4_1000 {
    width: 16.6666666667%;
  }
  .col5_1000 {
    width: 20.8333333333%;
  }
  .col6_1000 {
    width: 25%;
  }
  .col7_1000 {
    width: 29.1666666667%;
  }
  .col8_1000 {
    width: 33.3333333333%;
  }
  .col9_1000 {
    width: 37.5%;
  }
  .col10_1000 {
    width: 41.6666666667%;
  }
  .col11_1000 {
    width: 45.8333333333%;
  }
  .col12_1000 {
    width: 50%;
  }
  .col13_1000 {
    width: 54.1666666667%;
  }
  .col14_1000 {
    width: 58.3333333333%;
  }
  .col15_1000 {
    width: 62.5%;
  }
  .col16_1000 {
    width: 66.6666666667%;
  }
  .col17_1000 {
    width: 70.8333333333%;
  }
  .col18_1000 {
    width: 75%;
  }
  .col19_1000 {
    width: 79.1666666667%;
  }
  .col20_1000 {
    width: 83.3333333333%;
  }
  .col21_1000 {
    width: 87.5%;
  }
  .col22_1000 {
    width: 91.6666666667%;
  }
  .col23_1000 {
    width: 95.8333333333%;
  }
  .col24_1000 {
    width: 100%;
  }
  .col1_1000, .col2_1000, .col3_1000, .col4_1000, .col5_1000, .col6_1000,
  .col7_1000, .col8_1000, .col9_1000, .col10_1000, .col11_1000, .col12_1000,
  .col13_1000, .col14_1000, .col15_1000, .col16_1000, .col17_1000, .col18_1000,
  .col19_1000, .col20_1000, .col21_1000, .col22_1000, .col23_1000, .col24_1000 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 900px) {
  .col1_900 {
    width: 4.1666666667%;
  }
  .col2_900 {
    width: 8.3333333333%;
  }
  .col3_900 {
    width: 12.5%;
  }
  .col4_900 {
    width: 16.6666666667%;
  }
  .col5_900 {
    width: 20.8333333333%;
  }
  .col6_900 {
    width: 25%;
  }
  .col7_900 {
    width: 29.1666666667%;
  }
  .col8_900 {
    width: 33.3333333333%;
  }
  .col9_900 {
    width: 37.5%;
  }
  .col10_900 {
    width: 41.6666666667%;
  }
  .col11_900 {
    width: 45.8333333333%;
  }
  .col12_900 {
    width: 50%;
  }
  .col13_900 {
    width: 54.1666666667%;
  }
  .col14_900 {
    width: 58.3333333333%;
  }
  .col15_900 {
    width: 62.5%;
  }
  .col16_900 {
    width: 66.6666666667%;
  }
  .col17_900 {
    width: 70.8333333333%;
  }
  .col18_900 {
    width: 75%;
  }
  .col19_900 {
    width: 79.1666666667%;
  }
  .col20_900 {
    width: 83.3333333333%;
  }
  .col21_900 {
    width: 87.5%;
  }
  .col22_900 {
    width: 91.6666666667%;
  }
  .col23_900 {
    width: 95.8333333333%;
  }
  .col24_900 {
    width: 100%;
  }
  .col1_900, .col2_900, .col3_900, .col4_900, .col5_900, .col6_900,
  .col7_900, .col8_900, .col9_900, .col10_900, .col11_900, .col12_900,
  .col13_900, .col14_900, .col15_900, .col16_900, .col17_900, .col18_900,
  .col19_900, .col20_900, .col21_900, .col22_900, .col23_900, .col24_900 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 800px) {
  .col1_800 {
    width: 4.1666666667%;
  }
  .col2_800 {
    width: 8.3333333333%;
  }
  .col3_800 {
    width: 12.5%;
  }
  .col4_800 {
    width: 16.6666666667%;
  }
  .col5_800 {
    width: 20.8333333333%;
  }
  .col6_800 {
    width: 25%;
  }
  .col7_800 {
    width: 29.1666666667%;
  }
  .col8_800 {
    width: 33.3333333333%;
  }
  .col9_800 {
    width: 37.5%;
  }
  .col10_800 {
    width: 41.6666666667%;
  }
  .col11_800 {
    width: 45.8333333333%;
  }
  .col12_800 {
    width: 50%;
  }
  .col13_800 {
    width: 54.1666666667%;
  }
  .col14_800 {
    width: 58.3333333333%;
  }
  .col15_800 {
    width: 62.5%;
  }
  .col16_800 {
    width: 66.6666666667%;
  }
  .col17_800 {
    width: 70.8333333333%;
  }
  .col18_800 {
    width: 75%;
  }
  .col19_800 {
    width: 79.1666666667%;
  }
  .col20_800 {
    width: 83.3333333333%;
  }
  .col21_800 {
    width: 87.5%;
  }
  .col22_800 {
    width: 91.6666666667%;
  }
  .col23_800 {
    width: 95.8333333333%;
  }
  .col24_800 {
    width: 100%;
  }
  .col1_800, .col2_800, .col3_800, .col4_800, .col5_800, .col6_800,
  .col7_800, .col8_800, .col9_800, .col10_800, .col11_800, .col12_800,
  .col13_800, .col14_800, .col15_800, .col16_800, .col17_800, .col18_800,
  .col19_800, .col20_800, .col21_800, .col22_800, .col23_800, .col24_800 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 768px) {
  .col1_768 {
    width: 4.1666666667%;
  }
  .col2_768 {
    width: 8.3333333333%;
  }
  .col3_768 {
    width: 12.5%;
  }
  .col4_768 {
    width: 16.6666666667%;
  }
  .col5_768 {
    width: 20.8333333333%;
  }
  .col6_768 {
    width: 25%;
  }
  .col7_768 {
    width: 29.1666666667%;
  }
  .col8_768 {
    width: 33.3333333333%;
  }
  .col9_768 {
    width: 37.5%;
  }
  .col10_768 {
    width: 41.6666666667%;
  }
  .col11_768 {
    width: 45.8333333333%;
  }
  .col12_768 {
    width: 50%;
  }
  .col13_768 {
    width: 54.1666666667%;
  }
  .col14_768 {
    width: 58.3333333333%;
  }
  .col15_768 {
    width: 62.5%;
  }
  .col16_768 {
    width: 66.6666666667%;
  }
  .col17_768 {
    width: 70.8333333333%;
  }
  .col18_768 {
    width: 75%;
  }
  .col19_768 {
    width: 79.1666666667%;
  }
  .col20_768 {
    width: 83.3333333333%;
  }
  .col21_768 {
    width: 87.5%;
  }
  .col22_768 {
    width: 91.6666666667%;
  }
  .col23_768 {
    width: 95.8333333333%;
  }
  .col24_768 {
    width: 100%;
  }
  .col1_768, .col2_768, .col3_768, .col4_768, .col5_768, .col6_768,
  .col7_768, .col8_768, .col9_768, .col10_768, .col11_768, .col12_768,
  .col13_768, .col14_768, .col15_768, .col16_768, .col17_768, .col18_768,
  .col19_768, .col20_768, .col21_768, .col22_768, .col23_768, .col24_768 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 700px) {
  .col1_700 {
    width: 4.1666666667%;
  }
  .col2_700 {
    width: 8.3333333333%;
  }
  .col3_700 {
    width: 12.5%;
  }
  .col4_700 {
    width: 16.6666666667%;
  }
  .col5_700 {
    width: 20.8333333333%;
  }
  .col6_700 {
    width: 25%;
  }
  .col7_700 {
    width: 29.1666666667%;
  }
  .col8_700 {
    width: 33.3333333333%;
  }
  .col9_700 {
    width: 37.5%;
  }
  .col10_700 {
    width: 41.6666666667%;
  }
  .col11_700 {
    width: 45.8333333333%;
  }
  .col12_700 {
    width: 50%;
  }
  .col13_700 {
    width: 54.1666666667%;
  }
  .col14_700 {
    width: 58.3333333333%;
  }
  .col15_700 {
    width: 62.5%;
  }
  .col16_700 {
    width: 66.6666666667%;
  }
  .col17_700 {
    width: 70.8333333333%;
  }
  .col18_700 {
    width: 75%;
  }
  .col19_700 {
    width: 79.1666666667%;
  }
  .col20_700 {
    width: 83.3333333333%;
  }
  .col21_700 {
    width: 87.5%;
  }
  .col22_700 {
    width: 91.6666666667%;
  }
  .col23_700 {
    width: 95.8333333333%;
  }
  .col24_700 {
    width: 100%;
  }
  .col1_700, .col2_700, .col3_700, .col4_700, .col5_700, .col6_700,
  .col7_700, .col8_700, .col9_700, .col10_700, .col11_700, .col12_700,
  .col13_700, .col14_700, .col15_700, .col16_700, .col17_700, .col18_700,
  .col19_700, .col20_700, .col21_700, .col22_700, .col23_700, .col24_700 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 600px) {
  .col1_600 {
    width: 4.1666666667%;
  }
  .col2_600 {
    width: 8.3333333333%;
  }
  .col3_600 {
    width: 12.5%;
  }
  .col4_600 {
    width: 16.6666666667%;
  }
  .col5_600 {
    width: 20.8333333333%;
  }
  .col6_600 {
    width: 25%;
  }
  .col7_600 {
    width: 29.1666666667%;
  }
  .col8_600 {
    width: 33.3333333333%;
  }
  .col9_600 {
    width: 37.5%;
  }
  .col10_600 {
    width: 41.6666666667%;
  }
  .col11_600 {
    width: 45.8333333333%;
  }
  .col12_600 {
    width: 50%;
  }
  .col13_600 {
    width: 54.1666666667%;
  }
  .col14_600 {
    width: 58.3333333333%;
  }
  .col15_600 {
    width: 62.5%;
  }
  .col16_600 {
    width: 66.6666666667%;
  }
  .col17_600 {
    width: 70.8333333333%;
  }
  .col18_600 {
    width: 75%;
  }
  .col19_600 {
    width: 79.1666666667%;
  }
  .col20_600 {
    width: 83.3333333333%;
  }
  .col21_600 {
    width: 87.5%;
  }
  .col22_600 {
    width: 91.6666666667%;
  }
  .col23_600 {
    width: 95.8333333333%;
  }
  .col24_600 {
    width: 100%;
  }
  .col1_600, .col2_600, .col3_600, .col4_600, .col5_600, .col6_600,
  .col7_600, .col8_600, .col9_600, .col10_600, .col11_600, .col12_600,
  .col13_600, .col14_600, .col15_600, .col16_600, .col17_600, .col18_600,
  .col19_600, .col20_600, .col21_600, .col22_600, .col23_600, .col24_600 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 500px) {
  .col1_500 {
    width: 4.1666666667%;
  }
  .col2_500 {
    width: 8.3333333333%;
  }
  .col3_500 {
    width: 12.5%;
  }
  .col4_500 {
    width: 16.6666666667%;
  }
  .col5_500 {
    width: 20.8333333333%;
  }
  .col6_500 {
    width: 25%;
  }
  .col7_500 {
    width: 29.1666666667%;
  }
  .col8_500 {
    width: 33.3333333333%;
  }
  .col9_500 {
    width: 37.5%;
  }
  .col10_500 {
    width: 41.6666666667%;
  }
  .col11_500 {
    width: 45.8333333333%;
  }
  .col12_500 {
    width: 50%;
  }
  .col13_500 {
    width: 54.1666666667%;
  }
  .col14_500 {
    width: 58.3333333333%;
  }
  .col15_500 {
    width: 62.5%;
  }
  .col16_500 {
    width: 66.6666666667%;
  }
  .col17_500 {
    width: 70.8333333333%;
  }
  .col18_500 {
    width: 75%;
  }
  .col19_500 {
    width: 79.1666666667%;
  }
  .col20_500 {
    width: 83.3333333333%;
  }
  .col21_500 {
    width: 87.5%;
  }
  .col22_500 {
    width: 91.6666666667%;
  }
  .col23_500 {
    width: 95.8333333333%;
  }
  .col24_500 {
    width: 100%;
  }
  .col1_500, .col2_500, .col3_500, .col4_500, .col5_500, .col6_500,
  .col7_500, .col8_500, .col9_500, .col10_500, .col11_500, .col12_500,
  .col13_500, .col14_500, .col15_500, .col16_500, .col17_500, .col18_500,
  .col19_500, .col20_500, .col21_500, .col22_500, .col23_500, .col24_500 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 400px) {
  .col1_400 {
    width: 4.1666666667%;
  }
  .col2_400 {
    width: 8.3333333333%;
  }
  .col3_400 {
    width: 12.5%;
  }
  .col4_400 {
    width: 16.6666666667%;
  }
  .col5_400 {
    width: 20.8333333333%;
  }
  .col6_400 {
    width: 25%;
  }
  .col7_400 {
    width: 29.1666666667%;
  }
  .col8_400 {
    width: 33.3333333333%;
  }
  .col9_400 {
    width: 37.5%;
  }
  .col10_400 {
    width: 41.6666666667%;
  }
  .col11_400 {
    width: 45.8333333333%;
  }
  .col12_400 {
    width: 50%;
  }
  .col13_400 {
    width: 54.1666666667%;
  }
  .col14_400 {
    width: 58.3333333333%;
  }
  .col15_400 {
    width: 62.5%;
  }
  .col16_400 {
    width: 66.6666666667%;
  }
  .col17_400 {
    width: 70.8333333333%;
  }
  .col18_400 {
    width: 75%;
  }
  .col19_400 {
    width: 79.1666666667%;
  }
  .col20_400 {
    width: 83.3333333333%;
  }
  .col21_400 {
    width: 87.5%;
  }
  .col22_400 {
    width: 91.6666666667%;
  }
  .col23_400 {
    width: 95.8333333333%;
  }
  .col24_400 {
    width: 100%;
  }
  .col1_400, .col2_400, .col3_400, .col4_400, .col5_400, .col6_400,
  .col7_400, .col8_400, .col9_400, .col10_400, .col11_400, .col12_400,
  .col13_400, .col14_400, .col15_400, .col16_400, .col17_400, .col18_400,
  .col19_400, .col20_400, .col21_400, .col22_400, .col23_400, .col24_400 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@media screen and (max-width: 300px) {
  .col1_300 {
    width: 4.1666666667%;
  }
  .col2_300 {
    width: 8.3333333333%;
  }
  .col3_300 {
    width: 12.5%;
  }
  .col4_300 {
    width: 16.6666666667%;
  }
  .col5_300 {
    width: 20.8333333333%;
  }
  .col6_300 {
    width: 25%;
  }
  .col7_300 {
    width: 29.1666666667%;
  }
  .col8_300 {
    width: 33.3333333333%;
  }
  .col9_300 {
    width: 37.5%;
  }
  .col10_300 {
    width: 41.6666666667%;
  }
  .col11_300 {
    width: 45.8333333333%;
  }
  .col12_300 {
    width: 50%;
  }
  .col13_300 {
    width: 54.1666666667%;
  }
  .col14_300 {
    width: 58.3333333333%;
  }
  .col15_300 {
    width: 62.5%;
  }
  .col16_300 {
    width: 66.6666666667%;
  }
  .col17_300 {
    width: 70.8333333333%;
  }
  .col18_300 {
    width: 75%;
  }
  .col19_300 {
    width: 79.1666666667%;
  }
  .col20_300 {
    width: 83.3333333333%;
  }
  .col21_300 {
    width: 87.5%;
  }
  .col22_300 {
    width: 91.6666666667%;
  }
  .col23_300 {
    width: 95.8333333333%;
  }
  .col24_300 {
    width: 100%;
  }
  .col1_300, .col2_300, .col3_300, .col4_300, .col5_300, .col6_300,
  .col7_300, .col8_300, .col9_300, .col10_300, .col11_300, .col12_300,
  .col13_300, .col14_300, .col15_300, .col16_300, .col17_300, .col18_300,
  .col19_300, .col20_300, .col21_300, .col22_300, .col23_300, .col24_300 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%;
  }
}
@font-face {
  font-family: "Akkurat";
  src: url("../fonts//Akkurat/Akkurat-Bold.eot");
  src: url("../fonts//Akkurat/Akkurat-Bold.woff") format("woff"), url("../fonts//Akkurat/Akkurat-Bold.ttf") format("truetype"), url("../fonts//Akkurat/Akkurat-Bold.svg#Akkurat-Bold") format("svg"), url("../fonts//Akkurat/Akkurat-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat";
  src: url("../fonts//Akkurat/Akkurat-Normal.eot");
  src: url("../fonts//Akkurat/Akkurat-Normal.woff") format("woff"), url("../fonts//Akkurat/Akkurat-Normal.ttf") format("truetype"), url("../fonts//Akkurat/Akkurat-Normal.svg#Akkurat-Normal") format("svg"), url("../fonts//Akkurat/Akkurat-Normal.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Revolution";
  src: url("../fonts//Revolution/Revolution-Bold.eot");
  src: url("../fonts//Revolution/Revolution-Bold.woff2") format("woff2"), url("../fonts//Revolution/Revolution-Bold.woff") format("woff"), url("../fonts//Revolution/Revolution-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Revolution";
  src: url("../fonts//Revolution/Revolution-Regular.eot");
  src: url("../fonts//Revolution/Revolution-Regular.woff2") format("woff2"), url("../fonts//Revolution/Revolution-Regular.woff") format("woff"), url("../fonts//Revolution/Revolution-Regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
#overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  visibility: hidden;
  z-index: 9;
  transition: background 500ms ease, visibility 0ms ease 300ms, z-index 0ms ease 700ms;
}

.iconPlay {
  position: absolute;
  width: 50px;
  height: 50px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  z-index: 1;
  transition: border-color 300ms;
}
.iconPlay:after {
  content: "";
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 5px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.5);
  transition: border-color 300ms;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.table {
  display: table;
}

.tableCell {
  display: table-cell;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
  top: 50px;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.alignTop {
  vertical-align: top;
}

.alignMiddle {
  vertical-align: middle;
}

.alignBottom {
  vertical-align: bottom;
}

.caps {
  text-transform: capitalize;
}

.allCaps {
  text-transform: uppercase;
}

.wrapper1600 {
  max-width: 1600px;
  margin: 0 auto;
}
@media screen and (max-width: 1600px) {
  .wrapper1600 {
    margin: 0;
  }
}

.wrapper1500 {
  max-width: 1500px;
  margin: 0 auto;
}
@media screen and (max-width: 1500px) {
  .wrapper1500 {
    margin: 0;
  }
}

.wrapper1400 {
  max-width: 1400px;
  margin: 0 auto;
}
@media screen and (max-width: 1400px) {
  .wrapper1400 {
    margin: 0;
  }
}

.wrapper1300 {
  max-width: 1300px;
  margin: 0 auto;
}
@media screen and (max-width: 1300px) {
  .wrapper1300 {
    margin: 0;
  }
}

.wrapper1200 {
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .wrapper1200 {
    margin: 0;
  }
}

.wrapper1100 {
  max-width: 1100px;
  margin: 0 auto;
}
@media screen and (max-width: 1100px) {
  .wrapper1100 {
    margin: 0;
  }
}

.wrapper1024 {
  max-width: 1024px;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .wrapper1024 {
    margin: 0;
  }
}

.yPadding320 {
  padding-top: 320px;
  padding-bottom: 320px;
}
@media screen and (max-width: 1600px) {
  .yPadding320 {
    padding-top: 250px;
    padding-bottom: 250px;
  }
}
@media screen and (max-width: 1400px) {
  .yPadding320 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yPadding320 {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yPadding320 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yPadding320 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.yTopPadding320 {
  padding-top: 320px;
}
@media screen and (max-width: 1600px) {
  .yTopPadding320 {
    padding-top: 250px;
  }
}
@media screen and (max-width: 1400px) {
  .yTopPadding320 {
    padding-top: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yTopPadding320 {
    padding-top: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yTopPadding320 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yTopPadding320 {
    padding-top: 70px;
  }
}

.yBottomPadding320 {
  padding-bottom: 320px;
}
@media screen and (max-width: 1600px) {
  .yBottomPadding320 {
    padding-bottom: 250px;
  }
}
@media screen and (max-width: 1400px) {
  .yBottomPadding320 {
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yBottomPadding320 {
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yBottomPadding320 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yBottomPadding320 {
    padding-bottom: 70px;
  }
}

.yPadding250 {
  padding-top: 250px;
  padding-bottom: 250px;
}
@media screen and (max-width: 1600px) {
  .yPadding250 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 1400px) {
  .yPadding250 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yPadding250 {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yPadding250 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yPadding250 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.yTopPadding250 {
  padding-top: 250px;
}
@media screen and (max-width: 1600px) {
  .yTopPadding250 {
    padding-top: 200px;
  }
}
@media screen and (max-width: 1400px) {
  .yTopPadding250 {
    padding-top: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yTopPadding250 {
    padding-top: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yTopPadding250 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yTopPadding250 {
    padding-top: 70px;
  }
}

.yBottomPadding250 {
  padding-bottom: 250px;
}
@media screen and (max-width: 1600px) {
  .yBottomPadding250 {
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 1400px) {
  .yBottomPadding250 {
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yBottomPadding250 {
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yBottomPadding250 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yBottomPadding250 {
    padding-bottom: 70px;
  }
}

.yPadding200 {
  padding-top: 200px;
  padding-bottom: 200px;
}
@media screen and (max-width: 1600px) {
  .yPadding200 {
    padding-top: 175px;
    padding-bottom: 175px;
  }
}
@media screen and (max-width: 1400px) {
  .yPadding200 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yPadding200 {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yPadding200 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yPadding200 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.yTopPadding200 {
  padding-top: 200px;
}
@media screen and (max-width: 1600px) {
  .yTopPadding200 {
    padding-top: 175px;
  }
}
@media screen and (max-width: 1400px) {
  .yTopPadding200 {
    padding-top: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yTopPadding200 {
    padding-top: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yTopPadding200 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yTopPadding200 {
    padding-top: 70px;
  }
}

.yBottomPadding200 {
  padding-bottom: 200px;
}
@media screen and (max-width: 1600px) {
  .yBottomPadding200 {
    padding-bottom: 175px;
  }
}
@media screen and (max-width: 1400px) {
  .yBottomPadding200 {
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .yBottomPadding200 {
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .yBottomPadding200 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yBottomPadding200 {
    padding-bottom: 70px;
  }
}

.yPadding150 {
  padding-top: 150px;
  padding-bottom: 150px;
}
@media screen and (max-width: 1600px) {
  .yPadding150 {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1400px) {
  .yPadding150 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1200px) {
  .yPadding150 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .yPadding150 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yPadding150 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.yTopPadding150 {
  padding-top: 150px;
}
@media screen and (max-width: 1600px) {
  .yTopPadding150 {
    padding-top: 125px;
  }
}
@media screen and (max-width: 1400px) {
  .yTopPadding150 {
    padding-top: 100px;
  }
}
@media screen and (max-width: 1200px) {
  .yTopPadding150 {
    padding-top: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .yTopPadding150 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yTopPadding150 {
    padding-top: 70px;
  }
}

.yBottomPadding150 {
  padding-bottom: 150px;
}
@media screen and (max-width: 1600px) {
  .yBottomPadding150 {
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 1400px) {
  .yBottomPadding150 {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1200px) {
  .yBottomPadding150 {
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .yBottomPadding150 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yBottomPadding150 {
    padding-bottom: 70px;
  }
}

.yPadding100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media screen and (max-width: 1400px) {
  .yPadding100 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .yPadding100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yPadding100 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.yTopPadding100 {
  padding-top: 100px;
}
@media screen and (max-width: 1400px) {
  .yTopPadding100 {
    padding-top: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .yTopPadding100 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yTopPadding100 {
    padding-top: 70px;
  }
}

.yBottomPadding100 {
  padding-bottom: 100px;
}
@media screen and (max-width: 1400px) {
  .yBottomPadding100 {
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .yBottomPadding100 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yBottomPadding100 {
    padding-bottom: 70px;
  }
}

.yPadding70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.yTopPadding70 {
  padding-top: 70px;
}

.yBottomPadding70 {
  padding-bottom: 70px;
}

.yPadding50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.yTopPadding50 {
  padding-top: 50px;
}

.yBottomPadding50 {
  padding-bottom: 50px;
}

.xPadding20 {
  padding-right: 20%;
  padding-left: 20%;
}
@media screen and (max-width: 1400px) {
  .xPadding20 {
    padding-right: 12%;
    padding-left: 12%;
  }
}
@media screen and (max-width: 1200px) {
  .xPadding20 {
    padding-right: 10%;
    padding-left: 10%;
  }
}
@media screen and (max-width: 1024px) {
  .xPadding20 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding20 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.xPadding15 {
  padding-right: 15%;
  padding-left: 15%;
}
@media screen and (max-width: 1400px) {
  .xPadding15 {
    padding-right: 12%;
    padding-left: 12%;
  }
}
@media screen and (max-width: 1200px) {
  .xPadding15 {
    padding-right: 10%;
    padding-left: 10%;
  }
}
@media screen and (max-width: 1024px) {
  .xPadding15 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding15 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.xRightPadding15 {
  padding-right: 15%;
}
@media screen and (max-width: 1400px) {
  .xRightPadding15 {
    padding-right: 12%;
  }
}
@media screen and (max-width: 1200px) {
  .xRightPadding15 {
    padding-right: 10%;
  }
}
@media screen and (max-width: 1024px) {
  .xRightPadding15 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xRightPadding15 {
    padding-right: 20px;
  }
}

.xLeftPadding15 {
  padding-left: 15%;
}
@media screen and (max-width: 1400px) {
  .xLeftPadding15 {
    padding-left: 12%;
  }
}
@media screen and (max-width: 1200px) {
  .xLeftPadding15 {
    padding-left: 10%;
  }
}
@media screen and (max-width: 1024px) {
  .xLeftPadding15 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xLeftPadding15 {
    padding-left: 20px;
  }
}

.xPadding12 {
  padding-right: 12%;
  padding-left: 12%;
}
@media screen and (max-width: 1200px) {
  .xPadding12 {
    padding-right: 10%;
    padding-left: 10%;
  }
}
@media screen and (max-width: 1024px) {
  .xPadding12 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding12 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.xRightPadding12 {
  padding-right: 12%;
}
@media screen and (max-width: 1200px) {
  .xRightPadding12 {
    padding-right: 10%;
  }
}
@media screen and (max-width: 1024px) {
  .xRightPadding12 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xRightPadding12 {
    padding-right: 20px;
  }
}

.xLeftPadding12 {
  padding-left: 12%;
}
@media screen and (max-width: 1200px) {
  .xLeftPadding12 {
    padding-left: 10%;
  }
}
@media screen and (max-width: 1024px) {
  .xLeftPadding12 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xLeftPadding12 {
    padding-left: 20px;
  }
}

.xPadding8 {
  padding-right: 8%;
  padding-left: 8%;
}
@media screen and (max-width: 1200px) {
  .xPadding8 {
    padding-right: 6%;
    padding-left: 6%;
  }
}
@media screen and (max-width: 1024px) {
  .xPadding8 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding8 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.xRightPadding8 {
  padding-right: 8%;
}
@media screen and (max-width: 1200px) {
  .xRightPadding8 {
    padding-right: 6%;
  }
}
@media screen and (max-width: 1024px) {
  .xRightPadding8 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xRightPadding8 {
    padding-right: 20px;
  }
}

.xLeftPadding8 {
  padding-left: 8%;
}
@media screen and (max-width: 1200px) {
  .xLeftPadding8 {
    padding-left: 6%;
  }
}
@media screen and (max-width: 1024px) {
  .xLeftPadding8 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xLeftPadding8 {
    padding-left: 20px;
  }
}

.xPadding6 {
  padding-right: 6%;
  padding-left: 6%;
}
@media screen and (max-width: 1200px) {
  .xPadding6 {
    padding-right: 5%;
    padding-left: 5%;
  }
}
@media screen and (max-width: 1024px) {
  .xPadding6 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding6 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.xRightPadding6 {
  padding-right: 6%;
}
@media screen and (max-width: 1200px) {
  .xRightPadding6 {
    padding-right: 5%;
  }
}
@media screen and (max-width: 1024px) {
  .xRightPadding6 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xRightPadding6 {
    padding-right: 20px;
  }
}

.xLeftPadding6 {
  padding-left: 6%;
}
@media screen and (max-width: 1200px) {
  .xLeftPadding6 {
    padding-left: 5%;
  }
}
@media screen and (max-width: 1024px) {
  .xLeftPadding6 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xLeftPadding6 {
    padding-left: 20px;
  }
}

.xPadding5 {
  padding-right: 5%;
  padding-left: 5%;
}
@media screen and (max-width: 1024px) {
  .xPadding5 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding5 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.xRightPadding5 {
  padding-right: 5%;
}
@media screen and (max-width: 1024px) {
  .xRightPadding5 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xRightPadding5 {
    padding-right: 20px;
  }
}

.xLeftPadding5 {
  padding-left: 5%;
}
@media screen and (max-width: 1024px) {
  .xLeftPadding5 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xLeftPadding5 {
    padding-left: 20px;
  }
}

.xPadding50 {
  padding-right: 50px;
  padding-left: 50px;
}
@media screen and (max-width: 1024px) {
  .xPadding50 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xPadding50 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.xRightPadding50 {
  padding-right: 50px;
}
@media screen and (max-width: 1024px) {
  .xRightPadding50 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xRightPadding50 {
    padding-right: 20px;
  }
}

.xLeftPadding50 {
  padding-left: 50px;
}
@media screen and (max-width: 1024px) {
  .xLeftPadding50 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .xLeftPadding50 {
    padding-left: 20px;
  }
}

.noTopPadding {
  padding-top: 0;
}

.noRightPadding {
  padding-right: 0;
}

.noBottomPadding {
  padding-bottom: 0;
}

.noLeftPadding {
  padding-left: 0;
}

.noXPadding {
  padding-right: 0;
  padding-left: 0;
}

.noYPadding {
  padding-top: 0;
  padding-bottom: 0;
}

.noPadding {
  padding: 0;
}

.noTopMargin {
  margin-top: 0;
}

.noRightMargin {
  margin-right: 0;
}

.noBottomMargin {
  margin-bottom: 0;
}

.noLeftMargin {
  margin-left: 0;
}

.noXMargin {
  margin-right: 0;
  margin-left: 0;
}

.noYMargin {
  margin-top: 0;
  margin-bottom: 0;
}

.top0 {
  top: 0;
}

.right0 {
  right: 0;
}

.bottom0 {
  bottom: 0;
}

.left0 {
  left: 0;
}

.trbl0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 768px) {
  .noPaddingTop_768 {
    padding-top: 0;
  }
  .noPaddingBottom_768 {
    padding-bottom: 0;
  }
  .noXPadding_768 {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (max-width: 600px) {
  .noXPadding_600 {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (max-width: 500px) {
  .noXPadding_500 {
    padding-right: 0;
    padding-left: 0;
  }
}
.nowrap {
  white-space: nowrap;
}

.clearfix {
  display: block;
  clear: both;
}

.noscroll {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
@media screen and (max-width: 768px) {
  .noscroll {
    overflow-y: hidden;
  }
}

.noselect {
  user-select: none;
}

.hide {
  display: none;
}

@media screen and (max-width: 1800px) {
  .hide_1800 {
    display: none;
  }
}
@media screen and (max-width: 1700px) {
  .hide_1700 {
    display: none;
  }
}
@media screen and (max-width: 1600px) {
  .hide_1600 {
    display: none;
  }
}
@media screen and (max-width: 1500px) {
  .hide_1500 {
    display: none;
  }
}
@media screen and (max-width: 1400px) {
  .hide_1400 {
    display: none;
  }
}
@media screen and (max-width: 1300px) {
  .hide_1300 {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .hide_1200 {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .hide_1100 {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .hide_1024 {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .hide_1000 {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .hide_900 {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .hide_800 {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .hide_768 {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .hide_700 {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .hide_600 {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .hide_500 {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  .hide_400 {
    display: none;
  }
}
@media screen and (max-width: 300px) {
  .hide_300 {
    display: none;
  }
}
.show {
  display: block;
}

.show_1800 {
  display: none;
}
@media screen and (max-width: 1800px) {
  .show_1800 {
    display: block;
  }
}

.show_1700 {
  display: none;
}
@media screen and (max-width: 1700px) {
  .show_1700 {
    display: block;
  }
}

.show_1600 {
  display: none;
}
@media screen and (max-width: 1600px) {
  .show_1600 {
    display: block;
  }
}

.show_1500 {
  display: none;
}
@media screen and (max-width: 1500px) {
  .show_1500 {
    display: block;
  }
}

.show_1400 {
  display: none;
}
@media screen and (max-width: 1400px) {
  .show_1400 {
    display: block;
  }
}

.show_1300 {
  display: none;
}
@media screen and (max-width: 1300px) {
  .show_1300 {
    display: block;
  }
}

.show_1200 {
  display: none;
}
@media screen and (max-width: 1200px) {
  .show_1200 {
    display: block;
  }
}

.show_1100 {
  display: none;
}
@media screen and (max-width: 1100px) {
  .show_1100 {
    display: block;
  }
}

.show_1024 {
  display: none;
}
@media screen and (max-width: 1024px) {
  .show_1024 {
    display: block;
  }
}

.show_1000 {
  display: none;
}
@media screen and (max-width: 1000px) {
  .show_1000 {
    display: block;
  }
}

.show_900 {
  display: none;
}
@media screen and (max-width: 900px) {
  .show_900 {
    display: block;
  }
}

.show_800 {
  display: none;
}
@media screen and (max-width: 800px) {
  .show_800 {
    display: block;
  }
}

.show_768 {
  display: none;
}
@media screen and (max-width: 768px) {
  .show_768 {
    display: block;
  }
}

.show_700 {
  display: none;
}
@media screen and (max-width: 700px) {
  .show_700 {
    display: block;
  }
}

.show_600 {
  display: none;
}
@media screen and (max-width: 600px) {
  .show_600 {
    display: block;
  }
}

.show_500 {
  display: none;
}
@media screen and (max-width: 500px) {
  .show_500 {
    display: block;
  }
}

.show_400 {
  display: none;
}
@media screen and (max-width: 400px) {
  .show_400 {
    display: block;
  }
}

.show_300 {
  display: none;
}
@media screen and (max-width: 300px) {
  .show_300 {
    display: block;
  }
}

.zIndex-1 {
  z-index: -1;
}

.zIndex0 {
  z-index: 0;
}

.zIndex1 {
  z-index: 1;
}

.zIndex2 {
  z-index: 2;
}

.zIndex3 {
  z-index: 3;
}

.zIndex4 {
  z-index: 4;
}

.zIndex5 {
  z-index: 5;
}

.zIndex6 {
  z-index: 6;
}

.zIndex7 {
  z-index: 7;
}

.zIndex8 {
  z-index: 8;
}

.zIndex9 {
  z-index: 9;
}

.zIndex10 {
  z-index: 10;
}

.max600 {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

body {
  font-family: "Akkurat", "Times new roman";
}

.pageContainer {
  position: relative;
}

.decoration {
  background-image: url(../images/content/bg/grid.png);
  position: absolute;
  z-index: 1;
  display: block;
  pointer-events: none;
  opacity: 0.2;
}

.fixGridIndex {
  position: relative;
  z-index: 2;
}

.breadcrumbs {
  margin-bottom: 38px;
}
@media screen and (max-width: 768px) {
  .breadcrumbs {
    margin-bottom: 20px;
  }
}
.breadcrumbs li {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  font-family: "Revolution", "Times new roman";
  text-transform: uppercase;
}
.breadcrumbs li:not(:last-child) {
  padding-right: 25px;
}
.breadcrumbs li:not(:last-child):after {
  content: "/";
  position: absolute;
  width: 10px;
  height: 12px;
  top: 1px;
  right: 5px;
  color: white;
}
.breadcrumbs li a {
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.breadcrumbs li a svg {
  width: 20px;
  height: 20px;
}
.breadcrumbs li a svg polyline, .breadcrumbs li a svg line, .breadcrumbs li a svg path {
  fill: transparent;
}
.breadcrumbs li a:hover {
  color: #95c2ee;
}
.breadcrumbs li a, .breadcrumbs li p {
  display: inline-block;
  margin: 0px;
  color: white;
  font-size: 12px;
  line-height: 1.2em;
  letter-spacing: 0.2em;
}
.breadcrumbs li p {
  color: white;
}

.share {
  color: white;
  font-size: 12px;
  display: flex;
  height: 45px;
  align-items: center;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  z-index: 2;
}
.share svg {
  width: 15px;
  height: 15px;
  margin-left: 22px;
}
.share svg path {
  fill: white;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.share:hover {
  color: #95c2ee;
}
.share:hover svg rect, .share:hover svg polygon, .share:hover svg path {
  fill: #95c2ee;
}
.share.width {
  width: 150px;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Revolution", "Times new roman";
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: #2a3c47;
}
h1 span, h2 span, h3 span, h4 span, h5 span, h6 span,
.h1 span, .h2 span, .h3 span, .h4 span, .h5 span, .h6 span {
  display: block;
  padding-left: 45px;
}
h1.light, h2.light, h3.light, h4.light, h5.light, h6.light,
.h1.light, .h2.light, .h3.light, .h4.light, .h5.light, .h6.light {
  color: white;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child,
.h1:first-child, .h2:first-child, .h3:first-child, .h4:first-child, .h5:first-child, .h6:first-child {
  margin-top: 0;
}
h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child,
.h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child, .h6:last-child {
  margin-bottom: 0;
}

h1, .h1 {
  font-size: 70px;
  line-height: 1.0285714286em;
  margin-bottom: 48px;
}
@media screen and (max-width: 768px) {
  h1, .h1 {
    font-size: 60px;
  }
}
@media screen and (max-width: 500px) {
  h1, .h1 {
    font-size: 45px;
  }
}

h2, .h2 {
  font-size: 45px;
  margin-bottom: 48px;
}
@media screen and (max-width: 768px) {
  h2, .h2 {
    font-size: 35px;
  }
}

h3, .h3 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 35px;
  margin-top: 70px;
}
@media screen and (max-width: 768px) {
  h3, .h3 {
    font-size: 25px;
  }
}

h4, .h4 {
  font-size: 22px;
  text-transform: none;
  margin-bottom: 20px;
  margin-top: 45px;
}
@media screen and (max-width: 768px) {
  h4, .h4 {
    font-size: 20px;
  }
}

h5, .h5 {
  font-size: 15px;
  letter-spacing: 0.2em;
  color: #67a7e7;
  margin-bottom: 35px;
}

p {
  margin: 20px 0;
  font-size: 17px;
  line-height: 1.6470588235em;
  color: #777c7f;
}
p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

blockquote {
  font-size: 30px;
  padding: 10px 0 10px 40px;
  border-left: 4px solid #67a7e7;
  color: #67a7e7;
  font-family: "Revolution", "Times new roman";
  margin: 20px 0;
  line-height: 1.3333333333em;
}
@media screen and (max-width: 600px) {
  blockquote {
    font-size: 21px;
  }
}

.list.w-icon li {
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  padding-left: 0;
}
.list.w-icon li::before {
  content: normal;
}
.list.w-icon li a {
  display: block;
  padding: 20px 3%;
  font-size: 15px;
  color: #2a3c47;
  font-weight: bold;
  transition: all ease-in 0.3s;
}
.list.w-icon li a .listIcon {
  position: absolute;
  right: 40px;
  top: 50%;
  text-transform: uppercase;
  letter-spacing: 1px;
  transform: translateY(-50%);
}
.list.w-icon li a .listIcon svg {
  top: 15px;
  width: 15px;
}
.list.w-icon li a .listIcon svg .fill {
  fill: #67a7e7;
}
.list.w-icon li a:hover {
  color: #2a3c47;
}
.list.w-icon li a::before, .list.w-icon li a::after {
  content: normal;
}

.btn {
  display: inline-block;
  position: relative;
  z-index: 1;
  color: #2a3c47;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 16px 46px 12px 14px;
  transition: padding cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.btn:before {
  content: "";
  position: absolute;
  display: block;
  background-color: #fe6c4e;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  z-index: -1;
  height: 40px;
  width: 40px;
  transition: width cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.btn:after {
  content: "";
  background-image: url(../images/content/icons/arrow_btn.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  display: block;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 10px;
}
.btn:hover {
  padding-right: 60px;
}
.btn:hover:before {
  width: 100%;
}
.btn.light {
  color: white;
}
.btn.light:after {
  background-image: url(../images/content/icons/arrow_btn_white.svg);
}
.btn.blue:before {
  background-color: #95c2ee;
}
.btn.light2 {
  color: white;
}
.btn.light2:after {
  background-image: url(../images/content/icons/arrow_btn_white.svg);
}
@media screen and (max-width: 600px) {
  .btn.light2 {
    color: #000;
  }
  .btn.light2:after {
    background-image: url(../images/content/icons/arrow_btn.svg);
  }
}
.btn.blue2:before {
  background-color: #95c2ee;
}
@media screen and (max-width: 600px) {
  .btn.blue2:before {
    background-color: #fe6c4e;
  }
}
.btn.oc-loading::after {
  opacity: 1;
  background-image: none;
  margin-left: 0;
  transform: translate(-50%, -50%);
  top: 14px;
}

.dynamic ul li,
ul.liste li {
  margin: 20px 0;
  font-size: 17px;
  color: #777c7f;
  padding-left: 18px;
  position: relative;
}
.dynamic ul li:before,
ul.liste li:before {
  display: inline-block;
  content: "";
  border-radius: 0.375rem;
  height: 0.35rem;
  width: 0.35rem;
  background-color: #67a7e7;
  position: absolute;
  left: 0;
  top: 0.3em;
}
.dynamic ul.light li,
ul.liste.light li {
  color: white;
}

.dynamic li {
  margin: 20px 0;
  font-size: 17px;
  color: #777c7f;
  padding-left: 18px;
  position: relative;
}
.dynamic li:before {
  display: inline-block;
  content: "";
  border-radius: 0.375rem;
  height: 0.35rem;
  width: 0.35rem;
  background-color: #67a7e7;
  position: absolute;
  left: 0;
  top: 0.3em;
}

.dynamic a {
  position: relative;
  color: #777c7f;
  padding: 10px 0;
}
.dynamic a:before, .dynamic a:after {
  content: "";
  position: absolute;
  height: 2px;
  bottom: 0.7em;
  left: 0;
  transition: width cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.dynamic a::before {
  width: 100%;
  background-color: #777c7f;
  z-index: 1;
}
.dynamic a::after {
  width: 0;
  background-color: #67a7e7;
  z-index: 2;
}
.dynamic a:hover:after {
  width: 100%;
}

.heading {
  background-color: #2a3c47;
}

.stripe-loading-indicator {
  display: none;
}

.tiroirs {
  margin: 0;
  max-width: none;
  list-style-type: none;
}
.tiroirs > li > h2 {
  position: relative;
  margin: 0;
  cursor: pointer;
}
.tiroirs > li > .tiroir {
  display: none;
  max-width: 100%;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 300ms, transform 300ms;
}
.tiroirs > li.open > .tiroir {
  opacity: 1;
  transform: translateY(0);
  transition: transform 500ms ease 100ms, opacity 500ms ease 100ms;
}

.tiroirs.style1 > li {
  background: #f4f5f6;
  margin-top: 20px;
}
.tiroirs.style1 > li > .toggleTiroir {
  padding: 50px 12%;
  cursor: pointer;
  position: relative;
}
@media screen and (max-width: 1300px) {
  .tiroirs.style1 > li > .toggleTiroir {
    padding-right: 80px;
  }
}
@media screen and (max-width: 1024px) {
  .tiroirs.style1 > li > .toggleTiroir {
    padding-right: 110px;
  }
}
@media screen and (max-width: 768px) {
  .tiroirs.style1 > li > .toggleTiroir {
    padding-right: 80px;
  }
}
.tiroirs.style1 > li > .toggleTiroir h6 {
  margin-bottom: 18px;
  color: #67a7e7;
  letter-spacing: 0.2em;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  .tiroirs.style1 > li > .toggleTiroir h6 {
    font-size: 15px;
  }
}
@media screen and (max-width: 600px) {
  .tiroirs.style1 > li > .toggleTiroir h6 {
    font-size: 14px;
  }
}
.tiroirs.style1 > li > .toggleTiroir h2 {
  margin-top: 0;
  text-transform: none;
  font-size: 30px;
}
@media screen and (max-width: 1024px) {
  .tiroirs.style1 > li > .toggleTiroir h2 {
    font-size: 28px;
  }
}
@media screen and (max-width: 768px) {
  .tiroirs.style1 > li > .toggleTiroir h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 600px) {
  .tiroirs.style1 > li > .toggleTiroir h2 {
    font-size: 22px;
  }
}
.tiroirs.style1 > li > .toggleTiroir h2::after, .tiroirs.style1 > li > .toggleTiroir h2::before {
  content: "";
  position: absolute;
  background: #67a7e7;
}
.tiroirs.style1 > li > .toggleTiroir h2::after {
  width: 22px;
  height: 2px;
  margin-top: -1px;
  top: 50%;
  right: 80px;
  transition: background 400ms;
}
@media screen and (max-width: 1300px) {
  .tiroirs.style1 > li > .toggleTiroir h2::after {
    right: 30px;
  }
}
@media screen and (max-width: 1024px) {
  .tiroirs.style1 > li > .toggleTiroir h2::after {
    right: 60px;
  }
}
@media screen and (max-width: 768px) {
  .tiroirs.style1 > li > .toggleTiroir h2::after {
    right: 30px;
  }
}
.tiroirs.style1 > li > .toggleTiroir h2::before {
  width: 2px;
  height: 22px;
  margin-top: -11px;
  top: 50%;
  right: 90px;
  transition: transform 400ms, background 400ms;
}
@media screen and (max-width: 1300px) {
  .tiroirs.style1 > li > .toggleTiroir h2::before {
    right: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .tiroirs.style1 > li > .toggleTiroir h2::before {
    right: 70px;
  }
}
@media screen and (max-width: 768px) {
  .tiroirs.style1 > li > .toggleTiroir h2::before {
    right: 40px;
  }
}
.tiroirs.style1 > li > .tiroir .tiroirContenu {
  padding: 50px 10%;
}
.tiroirs.style1 > li > .tiroir div .adresse {
  margin-bottom: 15px;
}
.tiroirs.style1 > li > .tiroir div p {
  font-size: 15px;
}
.tiroirs.style1 > li:hover > .toggleTiroir, .tiroirs.style1 > li.open > .toggleTiroir {
  background-color: #e3e4e5;
}
.tiroirs.style1 > li.open > .toggleTiroir h3:before {
  background: transparent !important;
  transform: scaleY(0);
}
.tiroirs.style1 > li:first-child {
  margin-top: 0;
}
.tiroirs.style1 > li.withImage .toggleTiroir {
  padding-left: 210px;
}
@media screen and (max-width: 600px) {
  .tiroirs.style1 > li.withImage .toggleTiroir {
    padding-left: 10%;
  }
}
.tiroirs.style1 > li.withImage .thumb {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 170px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 600px) {
  .tiroirs.style1 > li.withImage .thumb {
    display: none;
  }
}
.tiroirs.style1 .share {
  margin-top: 40px;
  color: #777c7f;
}
.tiroirs.style1 .share svg path {
  fill: #2a3c47;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.tiroirs.style1 .share:hover svg path {
  fill: #67a7e7;
}

.homeSlider {
  background-color: black;
  position: relative;
  overflow-x: hidden;
}
.homeSlider .homeSlides {
  margin-bottom: 0;
}
.homeSlider .homeSlides .slide {
  min-height: 726px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
@media screen and (max-width: 1300px) {
  .homeSlider .homeSlides .slide {
    min-height: 580px;
  }
}
@media screen and (max-width: 1000px) {
  .homeSlider .homeSlides .slide {
    min-height: 450px;
  }
}
.homeSlider .homeSlides .slide .slideContent {
  top: 50%;
  position: absolute;
  z-index: 2;
  transform: translateY(-50%);
}
@media screen and (max-width: 1000px) {
  .homeSlider .homeSlides .slide .slideContent {
    padding-top: 155px;
  }
}
.homeSlider .homeSlides .slide .slideContent .overText,
.homeSlider .homeSlides .slide .slideContent .text {
  font-family: "Revolution", "Times new roman";
  color: white;
  text-transform: uppercase;
}
.homeSlider .homeSlides .slide .slideContent .overText {
  font-size: 15px;
  padding-bottom: 40px;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 1000px) {
  .homeSlider .homeSlides .slide .slideContent .overText {
    padding-bottom: 20px;
  }
}
.homeSlider .homeSlides .slide .slideContent .text {
  font-size: 60px;
  line-height: 1.064516129em;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 350px) {
  .homeSlider .homeSlides .slide .slideContent .text {
    font-size: 45px;
  }
}
.homeSlider .homeSlides .slide .slideContent .text span {
  display: block;
  margin-left: 56px;
}
@media screen and (max-width: 400px) {
  .homeSlider .homeSlides .slide .slideContent .text span {
    margin-left: 30px;
  }
}
.homeSlider .homeSlides .slide .slideContent a.btn {
  margin-top: 80px;
}
@media screen and (max-width: 1000px) {
  .homeSlider .homeSlides .slide .slideContent a.btn {
    margin-top: 40px;
  }
}
.homeSlider .slick-dots {
  top: 50%;
  bottom: auto;
  left: 0;
  width: 56px;
  z-index: 200;
  transform: translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .homeSlider .slick-dots {
    top: auto;
    transform: rotate(-90deg) translateY(-50%);
    bottom: 0;
    left: 50%;
    width: auto;
    height: 40px;
  }
}
.homeSlider .slick-dots li {
  display: block;
  margin: 0;
  height: auto;
  width: 56px;
}
@media screen and (max-width: 1024px) {
  .homeSlider .slick-dots li {
    width: 40px;
  }
}
.homeSlider .slick-dots li button {
  padding: 5px 0;
}
.homeSlider .slick-dots li button:before {
  content: "";
  background-color: white;
  width: 22px;
  height: 2px;
  display: block;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
  top: 50%;
  transform: translateY(-50%);
}
.homeSlider .slick-dots li.slick-active button:before {
  width: 56px;
}
@media screen and (max-width: 1024px) {
  .homeSlider .slick-dots li.slick-active button:before {
    width: 40px;
  }
}
.homeSlider .decoration {
  background-position: 0 6px;
  width: 270px;
  left: 0;
  height: 100%;
  bottom: 0;
  top: 0;
  background-image: url(../images/content/bg/grid_light.png);
  opacity: 0.3;
}
@media screen and (max-width: 768px) {
  .homeSlider .decoration {
    width: 149px;
  }
}

select {
  display: block;
  width: 100%;
  opacity: 0;
}

.chosen-container {
  position: relative;
  display: inline-block;
  width: 100% !important;
  font-size: 14px;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.chosen-container .chosen-single {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 0 0 20px;
  border: none;
  font: 15px/60px "MaisonNeue-Book", sans-serif;
  color: #333;
  cursor: pointer;
  white-space: nowrap;
  background-color: #fff;
  overflow: hidden;
  padding-right: 60px;
  z-index: 49;
  box-shadow: 3px 3px 10px rgba(51, 51, 51, 0.1);
  transition: padding 400ms, box-shadow 300ms;
}

.chosen-container .chosen-default {
  font: 15px/60px "MaisonNeue-Book", sans-serif;
  color: #333;
  letter-spacing: 1px;
}

.chosen-container-active {
  z-index: 50;
}

.chosen-container .chosen-single span {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.chosen-container .chosen-single div {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 60px;
  height: 100%;
  background: url("../images/content/icons/pointer.svg") no-repeat center center;
  background-size: 12px auto;
  transition: transform 500ms;
  -webkit-transition: -webkit-transform 500ms;
  -webkit-backface-visibility: visible;
  transform: rotate(90deg);
}

.chosen-container .chosen-single div b {
  display: none;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  box-shadow: none;
  padding-left: 20px;
}

.chosen-container-active.chosen-with-drop .chosen-single div {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotate(-90deg);
}

.chosen-container .chosen-drop {
  position: absolute;
  top: 0;
  width: 100%;
  border: none;
  border-top: none;
  background: #fff;
  visibility: hidden;
  z-index: 48;
  box-shadow: 3px 3px 10px rgba(51, 51, 51, 0.1);
  opacity: 0;
  transition: padding-top 500ms, opacity 500ms, box-shadow 500ms, visibility 0ms ease 500ms;
}

.chosen-container.chosen-with-drop .chosen-drop {
  top: 0;
  padding-top: 60px;
  border-top: solid 1px rgba(51, 51, 51, 0.3);
  visibility: visible;
  opacity: 1;
  transition: padding 400ms, opacity 400ms;
}

.chosen-container .chosen-results {
  position: relative;
  max-height: 204px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.chosen-container .chosen-results li {
  display: block !important;
  margin: 0;
  padding: 12px 20px;
  font: 15px/20px "MaisonNeue-Book", sans-serif;
  list-style: none;
  word-wrap: break-word;
  -webkit-touch-callout: none;
  transition: color 300ms;
}

.chosen-container .chosen-results li:first-child {
  padding-top: 25px;
  border-top: solid 1px rgba(51, 51, 51, 0.3);
}

.chosen-container .chosen-results li:last-child {
  padding-bottom: 25px;
}

.chosen-container .chosen-results li.active-result {
  cursor: pointer;
  color: #333;
}

.chosen-container .chosen-results li:hover {
  color: #95c2ee;
}

.chosen-container .chosen-results li.result-selected {
  font-family: "MaisonNeue-Book", sans-serif;
  cursor: default;
  color: #67a7e7 !important;
}

.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: rgba(51, 51, 51, 0.4);
  cursor: default;
}

.chosen-disabled {
  opacity: 0.35 !important;
  cursor: default;
}

.chosen-disabled .chosen-single {
  cursor: default;
}

.chosen-container-single .chosen-search {
  display: none;
}

#formZone .chosen-container .chosen-single {
  padding-left: 0;
  border-bottom: solid 1px rgba(51, 51, 51, 0.3);
  box-shadow: 0 0 0 rgba(51, 51, 51, 0);
}

#formZone .chosen-container-active.chosen-with-drop .chosen-single {
  padding-left: 20px;
  border-color: #e5e5e5;
}

#formZone select {
  padding-left: 0;
  border-bottom: solid 1px rgba(51, 51, 51, 0.3);
  box-shadow: 0 0 0 rgba(51, 51, 51, 0);
}

select {
  position: relative;
  display: block;
  font: 15px/60px "MaisonNeue-Book", sans-serif;
  padding: 0 60px 0 20px;
  height: 60px;
  background: #fff;
  opacity: 1;
  color: #333;
  width: 100%;
  box-shadow: 3px 3px 15px rgba(51, 51, 51, 0);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

select::-ms-expand {
  display: none;
}

.select-field {
  position: absolute;
  float: left;
  top: 250px;
  right: 160px;
  width: 320px;
  max-width: 100%;
  padding: 0;
}
.select-field:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 60px;
  content: "";
  cursor: pointer;
  pointer-events: none;
  background: url("../../images/icones/select-arrow.png") no-repeat center center;
  background-size: 12px auto;
}
@media screen and (max-width: 1000px) {
  .select-field {
    width: 250px;
  }
}
@media screen and (max-width: 768px) {
  .select-field {
    width: 300px;
  }
}

[data-fadeup] {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 500ms, transform 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
[data-fadeup][data-scrollfire=fire], [data-fadeup][data-multiscrollfire=fire] {
  opacity: 1;
  transform: none;
}

.transition [data-fadeup], .chargement [data-fadeup] {
  opacity: 0 !important;
  transform: translateY(100px) !important;
}
.transition [data-scrollfire][data-delay4], .transition [data-multiscrollfire][data-delay4], .chargement [data-scrollfire][data-delay4], .chargement [data-multiscrollfire][data-delay4] {
  transition-delay: 100ms;
}
.transition [data-scrollfire][data-delay3], .transition [data-multiscrollfire][data-delay3], .chargement [data-scrollfire][data-delay3], .chargement [data-multiscrollfire][data-delay3] {
  transition-delay: 200ms;
}
.transition [data-scrollfire][data-delay2], .transition [data-multiscrollfire][data-delay2], .chargement [data-scrollfire][data-delay2], .chargement [data-multiscrollfire][data-delay2] {
  transition-delay: 300ms;
}
.transition [data-scrollfire][data-delay1], .transition [data-multiscrollfire][data-delay1], .chargement [data-scrollfire][data-delay1], .chargement [data-multiscrollfire][data-delay1] {
  transition-delay: 400ms;
}
.transition [data-scrollfire][data-delay], .transition [data-multiscrollfire][data-delay], .chargement [data-scrollfire][data-delay], .chargement [data-multiscrollfire][data-delay] {
  transition-delay: 500ms;
}

[data-scrollfire], [data-multiscrollfire] {
  transition-delay: 0ms;
}
[data-scrollfire][data-delay], [data-multiscrollfire][data-delay] {
  transition-delay: 100ms;
}
[data-scrollfire][data-delay1], [data-multiscrollfire][data-delay1] {
  transition-delay: 300ms;
}
[data-scrollfire][data-delay2], [data-multiscrollfire][data-delay2] {
  transition-delay: 500ms;
}
[data-scrollfire][data-delay3], [data-multiscrollfire][data-delay3] {
  transition-delay: 700ms;
}
[data-scrollfire][data-delay4], [data-multiscrollfire][data-delay4] {
  transition-delay: 900ms;
}

#page-transition, #landing {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 9998;
  background: #67a7e7;
  visibility: hidden;
  transition: opacity 600ms, visibility 0ms ease 600ms;
}
#page-transition svg, #landing svg {
  position: absolute;
  width: 250px;
  height: 73px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#page-transition svg path, #page-transition svg polygon, #page-transition svg rect, #landing svg path, #landing svg polygon, #landing svg rect {
  fill: white;
}

.transition #page-transition, .chargement #page-transition {
  opacity: 1;
  visibility: visible;
  transition: opacity 600ms ease 1000ms;
}
form {
  display: block;
  width: 100%;
}

div.error {
  position: absolute;
  padding-top: 7px;
  top: 100%;
  font: 12px/1 "Akkurat", "Times new roman";
  color: #fe6c4e;
}

.input-field, .text-field, .select-field, .checkbox-field, .radiobox-field, .file-field {
  position: relative;
  width: 100%;
  margin-bottom: 80px;
}
@media screen and (max-width: 1024px) {
  .input-field, .text-field, .select-field, .checkbox-field, .radiobox-field, .file-field {
    margin-bottom: 65px;
  }
}
@media screen and (max-width: 600px) {
  .input-field, .text-field, .select-field, .checkbox-field, .radiobox-field, .file-field {
    margin-bottom: 45px;
  }
}

.input-field input {
  width: 100%;
  height: 45px;
  padding: 0px 0;
  border: none;
  border-bottom: 2px solid #2a3c47;
  font: 18px/45px "Akkurat", "Times new roman";
  color: #2a3c47;
  background: transparent;
  transition: 300ms ease;
  padding-bottom: 1px;
  -webkit-appearance: none;
  border-radius: 0;
}
.input-field input:focus {
  border-bottom-color: #67a7e7;
  border-bottom-width: 3px;
  padding-bottom: 0;
}
.input-field input.valid {
  border-bottom: 3px solid #67a7e7;
  padding-bottom: 0;
}
.input-field label {
  position: absolute;
  top: 0px;
  left: 0;
  font: 18px/45px "Akkurat", "Times new roman";
  color: #2a3c47;
  cursor: text;
  transition: 300ms cubic-bezier(0.19, 1, 0.22, 1);
}
.input-field input:focus + label, .input-field input.valid + label, .input-field input.error + label, .input-field input.notEmpty + label {
  top: -12px;
  font-size: 11px;
  line-height: 12px;
  cursor: default;
  pointer-events: none;
  color: #b1b1b1;
}
.input-field input.error {
  border-color: #fe6c4e;
}
.input-field input.vide + label {
  top: 0px;
  font: 16px/45px "Akkurat", "Times new roman";
}

.text-field textarea {
  width: 100%;
  height: 45px;
  min-height: 0;
  padding: 15px 0 0 0;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #2a3c47;
  font: 18px/1.1 "MaisonNeue-Book", sans-serif;
  color: #2a3c47;
  resize: none;
  overflow: hidden;
  padding-bottom: 1px;
  -webkit-appearance: none;
  border-radius: 0;
}
.text-field textarea:focus {
  min-height: 90px;
  border-bottom-color: #67a7e7;
  border-bottom-width: 3px;
  padding-bottom: 0;
}
.text-field textarea.valid {
  min-height: 90px;
  padding-bottom: 0;
  border-bottom: 3px solid #67a7e7;
}
.text-field textarea.error {
  border-color: #fe6c4e;
}
.text-field label {
  position: absolute;
  top: 0px;
  left: 0px;
  font: 18px/45px "Akkurat", "Times new roman";
  color: #2a3c47;
  cursor: text;
  transition: 300ms cubic-bezier(0.19, 1, 0.22, 1);
}
.text-field textarea:focus + label,
.text-field textarea.valid + label {
  top: -12px;
  font-size: 11px;
  line-height: 12px;
  cursor: default;
  pointer-events: none;
  color: #b1b1b1;
}

.file-field .uploadBtnWrapper {
  position: relative;
  display: inline-block;
}
.file-field .uploadBtnWrapper:after {
  background-image: none !important;
}
.file-field .uploadBtnWrapper input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.file-field .uploadBtnWrapper .btnUpload {
  display: block;
  color: #2a3c47;
  text-align: left;
  border: none;
  cursor: pointer;
}
.file-field .uploadBtnWrapper .btnUpload svg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  right: 15px;
}
.file-field .uploadBtnWrapper .btnUpload svg use {
  stroke: #2a3c47;
  fill: none;
}
.file-field.multi .uploadBtnWrapper {
  padding: 0;
  text-transform: none;
  width: 100%;
}
.file-field.multi .uploadBtnWrapper::before {
  content: normal;
}
.file-field.multi .uploadBtnWrapper:not(:last-child) {
  margin-bottom: 10px;
}
.file-field.multi .uploadBtnWrapper .close {
  display: none;
  position: absolute;
  top: 50%;
  right: 30px;
  height: 40px;
  width: 40px;
  background-color: #2a3c47;
  transform: translate(0, -50%);
  border-radius: 50%;
  transition: opacity 0.3s;
  z-index: 2;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .file-field.multi .uploadBtnWrapper .close {
    right: 15px;
    height: 35px;
    width: 35px;
  }
}
.file-field.multi .uploadBtnWrapper .close::before, .file-field.multi .uploadBtnWrapper .close::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 2px;
  background-color: white;
  top: 50%;
  left: 50%;
}
.file-field.multi .uploadBtnWrapper .close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.file-field.multi .uploadBtnWrapper .close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.file-field.multi .uploadBtnWrapper .close:hover {
  opacity: 0.7;
}
.file-field.multi .uploadBtnWrapper .btnUpload {
  height: 100px;
  width: 100%;
  background-color: #95c2ee;
}
.file-field.multi .uploadBtnWrapper .btnUpload span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 40px;
  padding-right: 100px;
  word-break: break-all;
  width: 100%;
  text-align: left;
  font: 18px/1.1 "MaisonNeue-Book", sans-serif;
}
@media screen and (max-width: 600px) {
  .file-field.multi .uploadBtnWrapper .btnUpload span {
    font-size: 15px;
  }
}
@media screen and (max-width: 400px) {
  .file-field.multi .uploadBtnWrapper .btnUpload span {
    font-size: 14px;
  }
}
.file-field.multi .uploadBtnWrapper .btnUpload svg {
  width: 30px;
  height: 30px;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  transition: opacity 0.3s;
}
.file-field.multi .uploadBtnWrapper:hover {
  padding-right: 0;
}
.file-field.multi .uploadBtnWrapper.notEmpty .close {
  display: block;
}
.file-field.multi .uploadBtnWrapper.notEmpty .btnUpload svg {
  display: none;
}
.file-field.multi .uploadBtnWrapper:hover svg {
  opacity: 0.6;
}

.multifield_title {
  font: 18px/45px "Akkurat", "Times new roman";
  color: #2a3c47;
  margin-bottom: 20px;
}

.envoyer {
  margin-top: 2%;
}
@media screen and (max-width: 1300px) {
  .envoyer {
    margin-top: 3%;
  }
}
@media screen and (max-width: 1024px) {
  .envoyer {
    margin-top: 2%;
  }
}

@media screen and (max-width: 600px) {
  .btns {
    width: 100%;
  }
}

.checkbox-field {
  position: relative;
}
.checkbox-field__option-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkbox-field__option-label {
  display: flex;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.checkbox-field__option-label:last-child {
  margin-bottom: 0;
}
.checkbox-field__option-label a {
  display: inline;
  color: #2a3c47;
  box-shadow: inset 0px -1px 0 #2a3c47;
  transition: all 300ms;
}
.checkbox-field__option-label a:hover {
  color: #fe6c4e;
  box-shadow: inset 0px -1px 0 #fe6c4e;
}
.checkbox-field__option-text {
  flex: 1;
  font: 18px/1.6 "MaisonNeue-Book", sans-serif;
  color: #2a3c47;
  order: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-field__option-box {
  position: relative;
  width: 16px;
  height: 16px;
  margin-top: 6.4px;
  margin-right: 12px;
  background-color: white;
  border: 1px solid #2a3c47;
  border-radius: 3px;
  order: 1;
  transition: background-color 300ms, border-color 300ms;
}
.checkbox-field__option-box::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 10px;
  height: 8px;
  background: url("../images/content/icons/checkmark.svg") no-repeat center/contain;
  transition: transform 300ms;
}
.checkbox-field__option-input:checked ~ .checkbox-field__option-box {
  background-color: #67a7e7;
  border-color: #67a7e7;
}
.checkbox-field__option-input:checked ~ .checkbox-field__option-box::before {
  transform: translate(-50%, -50%) scale(1);
}

.menuOverlay {
  position: fixed;
  width: 100%;
  top: -100vh;
  z-index: 250;
  height: 100vh;
  background-color: #67a7e7;
  transition: top 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  overflow-x: hidden;
  overflow-y: scroll;
}
.menuOverlay::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
@media screen and (max-width: 1024px) {
  .menuOverlay {
    padding-top: 120px;
    padding-bottom: 60px;
  }
}
.menuOverlay .mainMenu > ul {
  margin-top: -30px;
}
@media screen and (max-width: 1024px) {
  .menuOverlay .mainMenu > ul {
    margin-top: 0;
  }
}
@media screen and (max-width: 600px) {
  .menuOverlay .mainMenu > ul {
    margin-top: -50px;
  }
}
.menuOverlay .mainMenu > ul > li {
  opacity: 0;
  top: -20px;
  position: relative;
}
.menuOverlay .mainMenu > ul > li > a {
  display: block;
  color: #3a6580;
  font-family: "Revolution", "Times new roman";
  font-size: 70px;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 15px 0;
  transition: color cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
@media screen and (max-width: 1300px) {
  .menuOverlay .mainMenu > ul > li > a {
    font-size: 40px;
  }
}
@media screen and (max-width: 700px) {
  .menuOverlay .mainMenu > ul > li > a {
    font-size: 30px;
  }
}
@media screen and (max-width: 400px) {
  .menuOverlay .mainMenu > ul > li > a {
    font-size: 25px;
  }
}
@media screen and (max-width: 350px) {
  .menuOverlay .mainMenu > ul > li > a {
    padding: 10px 0;
  }
}
.menuOverlay .mainMenu > ul > li > a .number {
  opacity: 0;
  position: absolute;
  left: -46px;
  top: 22px;
  font-weight: normal;
  font-size: 21px;
  transition: left cubic-bezier(0.19, 1, 0.22, 1) 0.6s, opacity cubic-bezier(0.19, 1, 0.22, 1) 0.8s;
}
@media screen and (max-width: 1300px) {
  .menuOverlay .mainMenu > ul > li > a .number {
    top: 18px;
  }
}
@media screen and (max-width: 700px) {
  .menuOverlay .mainMenu > ul > li > a .number {
    font-size: 16px;
  }
}
.menuOverlay .mainMenu > ul > li:last-child {
  padding-bottom: 0;
}
.menuOverlay .mainMenu > ul > li.btnSubLvl1 .subLvl1 {
  display: none;
}
.menuOverlay .mainMenu > ul > li.btnSubLvl1 .subLvl1 li a {
  color: #3a6580;
  font-family: "Revolution", "Times new roman";
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 40px;
  padding: 8px 0;
}
@media screen and (max-width: 1300px) {
  .menuOverlay .mainMenu > ul > li.btnSubLvl1 .subLvl1 li a {
    font-size: 30px;
  }
}
@media screen and (max-width: 700px) {
  .menuOverlay .mainMenu > ul > li.btnSubLvl1 .subLvl1 li a {
    font-size: 22px;
  }
}
.menuOverlay .mainMenu > ul > li.btnSubLvl1 .subLvl1 li:first-child a {
  padding-top: 30px;
}
@media screen and (max-width: 1300px) {
  .menuOverlay .mainMenu > ul > li.btnSubLvl1 .subLvl1 li:first-child a {
    padding-top: 10px;
  }
}
.menuOverlay .mainMenu > ul > li.btnSubLvl1 .subLvl1 li:last-child a {
  padding-bottom: 60px;
}
@media screen and (max-width: 1300px) {
  .menuOverlay .mainMenu > ul > li.btnSubLvl1 .subLvl1 li:last-child a {
    padding-bottom: 40px;
  }
}
.menuOverlay .mainMenu > ul > li.active, .menuOverlay .mainMenu > ul > li:hover {
  padding-left: 46px;
}
@media screen and (max-width: 1300px) {
  .menuOverlay .mainMenu > ul > li.active, .menuOverlay .mainMenu > ul > li:hover {
    padding-left: 30px;
  }
}
@media screen and (min-width: 301px) {
  .menuOverlay .mainMenu > ul > li.active > a, .menuOverlay .mainMenu > ul > li:hover > a {
    color: white;
  }
  .menuOverlay .mainMenu > ul > li.active > a .number, .menuOverlay .mainMenu > ul > li:hover > a .number {
    opacity: 1;
    left: 0;
  }
  .menuOverlay .mainMenu > ul > li.active.btnSubLvl1 .subLvl1 li:hover a, .menuOverlay .mainMenu > ul > li:hover.btnSubLvl1 .subLvl1 li:hover a {
    color: white;
  }
}
.menuOverlay .mainMenu > ul > li:nth-child(1) {
  transition: padding cubic-bezier(0.19, 1, 0.22, 1) 0.8s, opacity cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.1s, top cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.1s;
}
.menuOverlay .mainMenu > ul > li:nth-child(2) {
  transition: padding cubic-bezier(0.19, 1, 0.22, 1) 0.8s, opacity cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.2s, top cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.2s;
}
.menuOverlay .mainMenu > ul > li:nth-child(3) {
  transition: padding cubic-bezier(0.19, 1, 0.22, 1) 0.8s, opacity cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.3s, top cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.3s;
}
.menuOverlay .mainMenu > ul > li:nth-child(4) {
  transition: padding cubic-bezier(0.19, 1, 0.22, 1) 0.8s, opacity cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.4s, top cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.4s;
}
.menuOverlay .mainMenu > ul > li:nth-child(5) {
  transition: padding cubic-bezier(0.19, 1, 0.22, 1) 0.8s, opacity cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.5s, top cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.5s;
}
.menuOverlay .mainMenu > ul > li:nth-child(6) {
  transition: padding cubic-bezier(0.19, 1, 0.22, 1) 0.8s, opacity cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.6s, top cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.6s;
}
.menuOverlay .mainMenu > ul > li:nth-child(7) {
  transition: padding cubic-bezier(0.19, 1, 0.22, 1) 0.8s, opacity cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.7s, top cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.7s;
}
.menuOverlay .mainMenu > ul > li:nth-child(8) {
  transition: padding cubic-bezier(0.19, 1, 0.22, 1) 0.8s, opacity cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.8s, top cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.8s;
}
.menuOverlay .mainMenu > ul > li:nth-child(9) {
  transition: padding cubic-bezier(0.19, 1, 0.22, 1) 0.8s, opacity cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.9s, top cubic-bezier(0.19, 1, 0.22, 1) 0.6s 0.9s;
}
.menuOverlay .mainMenu > ul > li:nth-child(10) {
  transition: padding cubic-bezier(0.19, 1, 0.22, 1) 0.8s, opacity cubic-bezier(0.19, 1, 0.22, 1) 0.6s 1s, top cubic-bezier(0.19, 1, 0.22, 1) 0.6s 1s;
}

.showMenuDropDown .pageContainer:before {
  content: "";
  position: absolute;
  top: 0;
  height: 71px;
  left: 0;
  right: 0;
  background: #67a7e7;
  z-index: 252;
}
.showMenuDropDown .menuOverlay {
  top: 0vh;
}
@media screen and (max-width: 1024px) {
  .showMenuDropDown .menuOverlay {
    position: absolute;
    min-height: 100vh;
  }
}
.showMenuDropDown .menuOverlay .mainMenu > ul > li {
  opacity: 1;
  top: 0;
}

.infos {
  color: white;
  font-size: 18px;
  line-height: 1.4583333333em;
  padding-bottom: 60px;
}
@media screen and (max-width: 1024px) {
  .infos {
    margin-top: 60px;
  }
}
@media screen and (max-width: 600px) {
  .infos {
    margin-top: 30px;
  }
}
@media screen and (max-width: 350px) {
  .infos {
    font-size: 16px;
    padding-bottom: 30px;
  }
}
.infos .big {
  font-family: "Revolution", "Times new roman";
  font-size: 30px;
  letter-spacing: 0.025em;
  margin-bottom: 20px;
}
@media screen and (max-width: 350px) {
  .infos .big {
    font-size: 22px;
    margin-bottom: 10px;
  }
}
.infos .big a {
  color: white;
}

.medias-sociaux-menu, .medias-sociaux-footer {
  display: flex;
  align-items: center;
  margin: 30px 0;
}
.medias-sociaux-menu .facebook-icon, .medias-sociaux-footer .facebook-icon {
  width: 35px;
  height: 35px;
  margin-right: 1px;
  padding: 0 10px 5px 0;
}
.medias-sociaux-menu .facebook-icon:hover, .medias-sociaux-footer .facebook-icon:hover {
  opacity: 0.5;
}
.medias-sociaux-menu .linkedin-icon, .medias-sociaux-footer .linkedin-icon {
  width: 35px;
  height: 35px;
  margin-left: 10px;
  padding: 0 0 5px 10px;
}
.medias-sociaux-menu .linkedin-icon:hover, .medias-sociaux-footer .linkedin-icon:hover {
  opacity: 0.5;
}
.medias-sociaux-menu .amq-icon, .medias-sociaux-footer .amq-icon {
  margin-left: 30px;
}
.medias-sociaux-menu .amq-icon img, .medias-sociaux-footer .amq-icon img {
  width: auto;
  height: 70px;
}

.medias-sociaux-footer {
  margin: 20px 0 0;
}
.medias-sociaux-footer .facebook-icon {
  width: 30px;
  height: 30px;
}
.medias-sociaux-footer .linkedin-icon {
  width: 30px;
  height: 30px;
}

header .topBar {
  position: absolute;
  z-index: 300;
  width: 100%;
}
header .topBar .left .logo {
  padding: 16px 40px;
  position: relative;
  z-index: 250;
}
@media screen and (max-width: 1024px) {
  header .topBar .left .logo {
    padding: 16px 30px;
  }
}
@media screen and (max-width: 768px) {
  header .topBar .left .logo {
    padding: 16px 20px;
  }
}
header .topBar .left .logo svg {
  width: 152px;
  height: 44px;
}
@media screen and (max-width: 350px) {
  header .topBar .left .logo svg {
    width: 110px;
    height: 32px;
  }
}
header .topBar .left .logo svg path, header .topBar .left .logo svg polygon, header .topBar .left .logo svg rect {
  fill: white;
}
header .topBar .right {
  position: absolute;
  top: 0;
  right: 0;
}
header .topBar .right ul {
  display: flex;
  overflow: hidden;
}
header .topBar .right ul li {
  z-index: 250;
  height: 100%;
}
header .topBar .right ul li a {
  padding: 30px;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.1em;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  height: 100%;
}
header .topBar .right ul li a:hover {
  opacity: 0.5;
}
@media screen and (max-width: 500px) {
  header .topBar .right ul li.langSwitcher a {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
header .topBar .right ul li.quote {
  background-color: white;
  transition: width cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
  width: 240px;
  text-align: center;
}
header .topBar .right ul li.quote a {
  color: #2a3c47;
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  header .topBar .right ul li.quote {
    width: 0;
    display: none;
  }
}
header .topBar .right ul li.menuToggle {
  background-color: #67a7e7;
  position: relative;
}
header .topBar .right ul li.menuToggle a {
  padding-right: 75px;
}
@media screen and (max-width: 500px) {
  header .topBar .right ul li.menuToggle a {
    padding-left: 0;
    padding-right: 0;
    width: 82px;
  }
  header .topBar .right ul li.menuToggle a .text {
    display: none;
  }
}
header .topBar .right ul li.menuToggle a .hamburger {
  width: 24px;
  height: 16px;
  display: block;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
header .topBar .right ul li.menuToggle a .hamburger:before,
header .topBar .right ul li.menuToggle a .hamburger .center,
header .topBar .right ul li.menuToggle a .hamburger .center:after, header .topBar .right ul li.menuToggle a .hamburger:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  display: block;
  opacity: 1;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
header .topBar .right ul li.menuToggle a .hamburger:before {
  top: 0;
}
header .topBar .right ul li.menuToggle a .hamburger .center {
  top: 50%;
  width: 65%;
  margin-left: 17.5%;
  transform: translateY(-50%);
}
header .topBar .right ul li.menuToggle a .hamburger:after {
  bottom: 0;
}
header .topBar .right ul li.menuToggle a .hamburger.open:before, header .topBar .right ul li.menuToggle a .hamburger.open:after {
  opacity: 0;
}
header .topBar .right ul li.menuToggle a .hamburger.open .center {
  transform: translateY(-50%) rotate(45deg);
}
header .topBar .right ul li.menuToggle a .hamburger.open .center:after {
  transform: rotate(90deg);
}

.showMenuDropDown header .topBar .right ul li.quote {
  width: 0;
  display: none;
}
.showMenuDropDown header .topBar .right ul li.quote a {
  display: none;
}
.showMenuDropDown header .topBar .right ul li.menuToggle a .hamburger:before, .showMenuDropDown header .topBar .right ul li.menuToggle a .hamburger:after {
  opacity: 0;
}
.showMenuDropDown header .topBar .right ul li.menuToggle a .hamburger .center {
  transform: translateY(-50%) rotate(45deg);
}
.showMenuDropDown header .topBar .right ul li.menuToggle a .hamburger .center:after {
  transform: rotate(90deg);
}

.vitrine {
  display: flex;
  flex-wrap: wrap;
}
.vitrine .item {
  width: 50%;
  background-color: #2a3c47;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  .vitrine .item {
    width: 100%;
  }
}
.vitrine .item a {
  display: flex;
  width: 100%;
  height: 220px;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-family: "Revolution", "Times new roman";
  text-transform: uppercase;
  font-size: 30px;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.vitrine .item a:hover {
  background-color: rgba(42, 60, 71, 0.6);
}

@media screen and (max-width: 768px) {
  footer .footerLeft .logoFooter {
    text-align: center;
    width: 100%;
  }
}
footer .footerLeft .logoFooter svg {
  width: 230px;
  height: 68px;
}
footer .footerLeft .logoFooter svg path, footer .footerLeft .logoFooter svg polygon, footer .footerLeft .logoFooter svg rect {
  fill: #2a3c47;
}
footer .footerLeft .logoFooter svg path.main, footer .footerLeft .logoFooter svg polygon.main, footer .footerLeft .logoFooter svg rect.main {
  fill: #67a7e7;
}
footer .footerLeft .underLogo {
  color: #969a9c;
  font-size: 17px;
  line-height: 1.5882352941em;
}
footer .footerLeft .underLogo a {
  color: #969a9c;
}
footer .footerLeft .underLogo a:hover {
  text-decoration: underline;
}
footer .footerLeft .underLogo .map-link {
  color: #2a3c47;
}
@media screen and (max-width: 768px) {
  footer .footerLeft .underLogo div {
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  footer .footerLeft .underLogo .paddingTopMobile {
    padding-top: 30px;
  }
}
footer .footerLeft .underLogo .header {
  font-family: "Revolution", "Times new roman";
  color: #67a7e7;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 768px) {
  footer .footerRight {
    margin-top: 60px;
  }
}
footer .footerRight ul {
  margin-top: -10px;
}
@media screen and (max-width: 768px) {
  footer .footerRight ul li {
    text-align: center;
  }
}
footer .footerRight ul li a {
  position: relative;
  color: #2a3c47;
  padding: 10px 0;
}
footer .footerRight ul li a:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 0;
  bottom: 5px;
  background-color: #67a7e7;
  transition: width cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
footer .footerRight ul li a:hover:before {
  width: 100%;
}

.subFooter {
  background-color: #2a3c47;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #82898e;
  font-size: 11px;
}
.subFooter a {
  color: #82898e;
  transition: color cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.subFooter a:hover {
  color: white;
}
.subFooter .subFooterRight {
  text-align: right;
}
@media screen and (max-width: 600px) {
  .subFooter .subFooterRight {
    margin-top: 15px;
  }
}
@media screen and (max-width: 600px) {
  .subFooter .subFooterLeft,
  .subFooter .subFooterRight {
    text-align: center;
  }
}

#shareModalOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: visible;
  background: rgba(0, 0, 0, 0);
  z-index: 201;
  visibility: hidden;
  transition: background 300ms, visibility 0ms ease 300ms;
}

#shareModal {
  position: fixed;
  left: 50%;
  top: 50%;
  margin: 20px 0 0 0;
  padding: 100px 0px;
  width: 500px;
  max-width: calc(100% - 40px);
  max-height: 80%;
  text-align: center;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  z-index: 202;
  transform: translate(-50%, -50%);
  transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 250ms, margin 250ms, visibility 0ms ease 250ms;
}
@media screen and (max-width: 700px) {
  #shareModal {
    width: 420px;
    padding: 70px;
  }
}
@media screen and (max-width: 500px) {
  #shareModal {
    padding: 70px 20px 50px 20px;
  }
}
@media screen and (max-width: 400px) {
  #shareModal {
    padding: 60px 20px 40px 20px;
  }
}
#shareModal .h3 {
  margin-top: 0;
}
#shareModal .h4 {
  margin-bottom: 40px;
  font-family: "Revolution", "Times new roman";
  font-size: 30px;
}
@media screen and (max-width: 1400px) {
  #shareModal .h4 {
    margin-bottom: 30px;
    font-size: 30px;
  }
}
@media screen and (max-width: 700px) {
  #shareModal .h4 {
    font-size: 28px;
  }
}
@media screen and (max-width: 600px) {
  #shareModal .h4 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 400px) {
  #shareModal .h4 {
    font-size: 24px;
  }
}
#shareModal > a {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
}
#shareModal > a .icon {
  width: 35px;
  height: 35px;
  stroke: #67a7e7;
  vertical-align: middle;
  stroke-width: 8px;
}
#shareModal .medias-sociaux {
  display: flex;
  padding: 0 20px;
  justify-content: center;
}
#shareModal .medias-sociaux a {
  margin: 0 10px;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
@media screen and (max-width: 400px) {
  #shareModal .medias-sociaux a {
    width: 40px;
    height: 40px;
  }
}
#shareModal .medias-sociaux a svg {
  width: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#shareModal .medias-sociaux a svg .fill {
  fill: #2a3c47;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
@media screen and (max-width: 600px) {
  #shareModal .medias-sociaux a {
    margin: 4px 6px;
  }
}
#shareModal .medias-sociaux a:hover svg .fill {
  fill: #86909d;
}

.show-shareModal #shareModalOverlay {
  visibility: visible;
  background: rgba(0, 0, 0, 0.5);
  transition: background 400ms;
}
.show-shareModal #shareModal {
  opacity: 1;
  margin-top: 0;
  visibility: visible;
  transition: opacity 400ms, margin 400ms;
  transition: opacity 400ms, margin 400ms;
}

#shareCloseBtn svg .fill {
  fill: #67a7e7;
  stroke: #67a7e7;
}

section.heading {
  position: relative;
}
@media screen and (max-width: 1024px) {
  section.heading {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
section.heading .headingContent h1 {
  max-width: 50%;
}
@media screen and (max-width: 1600px) {
  section.heading .headingContent h1 {
    max-width: 70%;
  }
}
@media screen and (max-width: 1300px) {
  section.heading .headingContent h1 {
    max-width: 100%;
  }
}
section.heading .share {
  position: absolute;
  bottom: 30px;
  right: 40px;
}
@media screen and (max-width: 768px) {
  section.heading .share {
    left: 20px;
  }
}
section.heading .decoration {
  background-position: 0 6px;
  width: 270px;
  left: 0;
  height: 100%;
  bottom: 0;
  top: 0;
  background-image: url(../images/content/bg/grid_light.png);
  opacity: 0.3;
}
@media screen and (max-width: 768px) {
  section.heading .decoration {
    width: 149px;
  }
}

section.mobileImage {
  height: 320px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

section.mainText {
  position: relative;
}
@media screen and (max-width: 768px) {
  section.mainText {
    padding-left: 20px;
    padding-right: 20px;
  }
}
section.mainText.publication {
  min-height: 700px;
}
@media screen and (max-width: 1300px) {
  section.mainText.publication {
    min-height: 800px;
  }
}
@media screen and (max-width: 768px) {
  section.mainText.publication {
    min-height: 0;
  }
}
section.mainText .leftCol {
  width: 33.33333333%;
  position: absolute;
  top: -150px;
  bottom: 0;
  left: 0;
  z-index: 3;
  text-align: center;
}
@media screen and (max-width: 1300px) {
  section.mainText .leftCol {
    top: -50px;
  }
}
@media screen and (max-width: 768px) {
  section.mainText .leftCol {
    top: auto;
    width: 100%;
    position: static;
  }
}
section.mainText .leftCol .decorationImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 630px;
  height: 100%;
}
@media screen and (max-width: 768px) {
  section.mainText .leftCol .decorationImage {
    display: none;
  }
}
section.mainText .leftCol .share {
  display: inline-block;
  color: #777c7f;
  font-weight: bold;
  height: auto;
}
section.mainText .leftCol .share svg {
  position: relative;
  top: 3px;
}
section.mainText .leftCol .share svg path {
  fill: #2a3c47;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
section.mainText .leftCol .share:hover svg path {
  fill: #67a7e7;
}
section.mainText .leftCol .sidePaddingShare {
  padding-top: 50px;
  padding-bottom: 15px;
}
section.mainText .leftCol .sidePaddingBack {
  padding-top: 15px;
  padding-bottom: 50px;
}
section.mainText .leftCol hr {
  height: 1px;
  border: 0;
  background-color: #e7e7ea;
}

section.qualifiedEmployees {
  position: relative;
}
section.qualifiedEmployees .decorationImage {
  width: 33.33333333%;
  position: absolute;
  top: -150px;
  bottom: 86px;
  right: 0;
  z-index: 3;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1100px) {
  section.qualifiedEmployees .decorationImage {
    top: -80px;
  }
}
@media screen and (max-width: 768px) {
  section.qualifiedEmployees .decorationImage {
    display: none;
  }
}

section.ourExpertise {
  position: relative;
}
@media screen and (max-width: 600px) {
  section.ourExpertise h2.light, section.ourExpertise a.btn.light {
    color: #2a3c47;
  }
}
@media screen and (max-width: 600px) {
  section.ourExpertise .row .sectionTitle {
    padding: 0 0 50px 0;
  }
}
@media screen and (max-width: 600px) {
  section.ourExpertise .textContainer {
    padding: 30px 0 70px 0;
  }
}
@media screen and (max-width: 768px) {
  section.ourExpertise .expertises .expertise {
    margin-bottom: 20px;
  }
  section.ourExpertise .expertises .expertise:last-child {
    margin-bottom: 0;
  }
}
section.ourExpertise .expertises .expertise a {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}
section.ourExpertise .expertises .expertise a .expertiseContent {
  padding: 320px 30px 60px 30px;
  text-align: center;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
@media screen and (max-width: 768px) {
  section.ourExpertise .expertises .expertise a .expertiseContent {
    padding-top: 150px;
  }
}
section.ourExpertise .expertises .expertise a .expertiseContent .number {
  font-size: 78px;
  font-weight: bold;
  color: white;
  line-height: 1em;
  font-family: "Revolution", "Times new roman";
  margin-bottom: 30px;
}
section.ourExpertise .expertises .expertise a .expertiseContent h3 {
  font-weight: normal;
  margin-top: 0;
}
@media screen and (max-width: 900px) {
  section.ourExpertise .expertises .expertise a .expertiseContent h3 {
    font-size: 25px;
  }
}
section.ourExpertise .expertises .expertise a:hover .expertiseContent {
  background-color: rgba(42, 60, 71, 0.6);
}
section.ourExpertise .decoration1 {
  background-position: 6px 6px;
  width: 50%;
  right: 0;
  height: 276px;
  bottom: 0;
}
section.ourExpertise .decoration2 {
  background-position: 0px 6px;
  width: 270px;
  left: 0;
  height: 10000px;
  bottom: 140px;
}
@media screen and (max-width: 768px) {
  section.ourExpertise .decoration2 {
    width: 149px;
  }
}
section.ourExpertise:after {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: #67a7e7;
  display: block;
  width: 50%;
  left: 0;
  top: 0;
  bottom: 140px;
}
@media screen and (max-width: 600px) {
  section.ourExpertise:after {
    top: auto;
    height: 1110px;
    bottom: 50px;
  }
}

section.difference > div h5, section.difference > div h2 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.bottomGrid {
  position: relative;
}
.bottomGrid .decoration3 {
  background-position: -5px 6px;
  width: 270px;
  bottom: 0;
  top: 80px;
  right: 0;
}
@media screen and (max-width: 768px) {
  .bottomGrid .decoration3 {
    width: 149px;
  }
}

section.photo .photoContainer {
  width: 100%;
  padding-bottom: 45%;
  background-color: #2a3c47;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  section.photo .photoContainer {
    height: 300px;
  }
}

section.jobs .images {
  position: relative;
}
@media screen and (max-width: 768px) {
  section.jobs .images .jobTexts .text {
    min-height: 550px;
  }
}
section.jobs .images .jobImages .jobImage {
  width: 650px;
  height: 568px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 1600px) {
  section.jobs .images .jobImages .jobImage {
    width: 465px;
  }
}
@media screen and (max-width: 768px) {
  section.jobs .images .jobImages .jobImage {
    height: 360px;
  }
}
section.jobs .images .slick-slide {
  margin-right: 60px;
}
section.jobs .images .arrows {
  position: absolute;
  height: 60px;
  width: 80px;
  bottom: -60px;
  left: 0;
  display: flex;
}
@media screen and (max-width: 1024px) {
  section.jobs .images .arrows {
    left: 50%;
    transform: translateX(-50%);
  }
}
section.jobs .images .arrows .prec,
section.jobs .images .arrows .next {
  position: relative;
  width: 50%;
  cursor: pointer;
  transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
section.jobs .images .arrows .prec:after,
section.jobs .images .arrows .next:after {
  content: "";
  background-image: url(../images/content/icons/arrow_btn.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  display: block;
  width: 20px;
  height: 10px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
section.jobs .images .arrows .prec:hover,
section.jobs .images .arrows .next:hover {
  opacity: 0.6;
}
section.jobs .images .arrows .prec:after {
  transform: translateY(-50%) rotate(180deg);
  right: auto;
}

.theLargeNews,
.theNews {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-right: -30px;
}
@media screen and (max-width: 768px) {
  .theLargeNews,
  .theNews {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.theLargeNews .news,
.theNews .news {
  padding: 0 30px;
}
@media screen and (max-width: 768px) {
  .theLargeNews .news,
  .theNews .news {
    padding: 0 15px;
  }
}
.theLargeNews .news .image,
.theNews .news .image {
  width: 100%;
  background-color: #2a3c47;
  padding-bottom: 66%;
  position: relative;
  overflow: hidden;
}
.theLargeNews .news .image span,
.theNews .news .image span {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.theLargeNews .news .date,
.theNews .news .date {
  padding-top: 40px;
}
.theLargeNews .news .title h2,
.theNews .news .title h2 {
  font-weight: normal;
  text-transform: none;
  margin-top: 15px;
}
.theLargeNews .news:hover .image span,
.theNews .news:hover .image span {
  transform: translate(-50%, -50%) scale(1.1);
}

.theLargeNews {
  margin-top: -100px;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .theLargeNews {
    margin-top: -50px;
  }
}
.theLargeNews .news {
  width: 50%;
}
@media screen and (max-width: 600px) {
  .theLargeNews .news {
    width: 100%;
  }
}
.theLargeNews .news .image {
  padding-bottom: 83%;
}
@media screen and (max-width: 600px) {
  .theLargeNews .news:first-child {
    padding-bottom: 70px;
  }
}

.theNews .news {
  width: 33.333333333%;
  padding-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .theNews .news {
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .theNews .news {
    width: 100%;
  }
}

section.mainText .contactCol {
  width: 33.33333333%;
  position: absolute;
  top: -100px;
  left: 0;
  z-index: 3;
  color: white;
}
@media screen and (max-width: 1300px) {
  section.mainText .contactCol {
    top: -50px;
  }
}
@media screen and (max-width: 1024px) {
  section.mainText .contactCol {
    top: auto;
    width: 100%;
    position: static;
  }
}
section.mainText .contactCol h3 {
  background-color: rgba(0, 0, 0, 0.2);
  line-height: 100px;
  margin-bottom: 0;
}
section.mainText .contactCol .coordonneesContainer {
  background-color: #67a7e7;
}
section.mainText .contactCol .coordonneesContainer h5, section.mainText .contactCol .coordonneesContainer p, section.mainText .contactCol .coordonneesContainer a {
  color: white;
}
section.mainText .contactCol .coordonneesContainer h5 {
  font-size: 12px;
  margin-bottom: 20px;
}
section.mainText .contactCol .coordonneesContainer .big {
  font-size: 25px;
  line-height: 1.4em;
}
section.mainText .contactCol .coordonneesContainer a {
  font-weight: bold;
}
section.mainText .contactCol .coordonneesContainer--white {
  background-color: transparent;
}
section.mainText .contactCol .coordonneesContainer--white h5, section.mainText .contactCol .coordonneesContainer--white p, section.mainText .contactCol .coordonneesContainer--white a {
  color: #2a3c47;
}
@media screen and (max-width: 1024px) {
  section.mainText .contactCol .coordonneesContainer--white {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 1024px) {
  section.mainText .rightContent {
    padding-left: 30px;
    padding-top: 70px;
  }
}
@media screen and (max-width: 768px) {
  section.mainText .rightContent {
    padding-left: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .heading.innovation {
    padding-top: 0;
  }
}
@media screen and (max-width: 1024px) {
  .heading.innovation .headingContent .responsiveGrid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.heading.innovation .headingContent h3 {
  font-size: 45px;
  font-weight: normal;
}
.heading.innovation .headingContent .fixedTitle {
  position: sticky;
  top: 60px;
}
@media screen and (max-width: 1024px) {
  .heading.innovation .headingContent .fixedTitle {
    position: static;
  }
}
.heading.innovation .headingContent p {
  padding-right: 100px;
  color: #fff;
}
@media screen and (max-width: 1024px) {
  .heading.innovation .headingContent p {
    padding-right: 0;
  }
}
.heading.innovation .headingContent .innovationBlock {
  margin-top: 50px;
}
@media screen and (max-width: 1024px) {
  .heading.innovation .headingContent .innovationBlock {
    width: 50%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 768px) {
  .heading.innovation .headingContent .innovationBlock {
    width: 100%;
  }
}
.heading.innovation .headingContent .innovationBlock .icon {
  padding-left: 20px;
  padding-top: 12px;
  position: relative;
}
.heading.innovation .headingContent .innovationBlock .icon svg {
  width: 62px;
  position: relative;
  z-index: 1;
}
.heading.innovation .headingContent .innovationBlock .icon::after {
  content: "";
  display: block;
  height: 70px;
  width: 70px;
  background-color: #67a7e7;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 70px;
  z-index: -1;
}
.heading.innovation .headingContent .innovationBlock h4 {
  font-size: 30px;
  margin-top: 20px;
  font-weight: bold;
}
.heading.innovation .headingContent .innovationBlock .text {
  color: #8c9092;
  font-size: 15px;
  line-height: 1.666666em;
}
.heading.innovation .headingContent .innovationBlock:first-child {
  margin-top: 0;
}
@media screen and (max-width: 1024px) {
  .heading.innovation .headingContent .innovationBlock:first-child {
    margin-top: auto;
  }
}
@media screen and (max-width: 768px) {
  .heading.innovation .headingContent .innovationBlock:first-child {
    margin-top: 75px;
  }
}
.heading.innovation .decoration {
  background-position: 0 3px;
}

section.lookout {
  color: white;
  position: relative;
}
section.lookout .photoContainer span {
  display: block;
  width: 100%;
  height: 525px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  section.lookout .photoContainer span {
    height: 300px;
  }
}
section.lookout .newTendencies {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
section.lookout .newTendencies .left {
  width: 40%;
}
section.lookout .newTendencies .right {
  width: 60%;
  display: flex;
  justify-content: center;
}
section.lookout .newTendencies .right .content {
  padding: 60px;
  padding-left: 100px;
}
@media screen and (max-width: 768px) {
  section.lookout .newTendencies .right .content {
    padding: 0;
    padding-left: 32px;
  }
}
section.lookout .newTendencies .right .content p {
  color: #fff;
}
section.lookout .newTendencies img {
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  section.lookout .newTendencies .left {
    display: none;
  }
  section.lookout .newTendencies .right {
    width: 100%;
  }
}
section.lookout:after {
  content: "";
  background-color: #67a7e7;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 262px;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  section.lookout:after {
    top: 150px;
  }
}
section.lookout .decoration {
  left: 0;
  top: 262px;
  bottom: 0;
  width: 270px;
  background-position: 0 6px;
}
@media screen and (max-width: 768px) {
  section.lookout .decoration {
    top: 150px;
    width: 149px;
  }
}

section.testimonials {
  color: white;
}
section.testimonials .photoContainer span {
  display: block;
  width: 100%;
  height: 525px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  section.testimonials .photoContainer span {
    height: 300px;
  }
}
section.testimonials .testimonialTexts {
  display: flex;
  height: 568px;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  section.testimonials .testimonialTexts {
    height: auto;
  }
}
section.testimonials .testimonialTexts .text {
  position: relative;
}
section.testimonials .testimonialTexts .text p {
  color: white;
  font-size: 17px;
}
section.testimonials .testimonialTexts .text .author {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 12px;
  font-weight: bold;
  padding-left: 22px;
  position: relative;
  margin-top: 40px;
}
section.testimonials .testimonialTexts .text .author::before {
  content: "";
  width: 13px;
  height: 2px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
section.testimonials .images {
  position: relative;
}
@media screen and (max-width: 600px) {
  section.testimonials .images .testimonialImages {
    padding-top: 70px;
  }
}
section.testimonials .images .testimonialImages .testimonialImage {
  width: 650px;
  height: 568px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 1600px) {
  section.testimonials .images .testimonialImages .testimonialImage {
    width: 465px;
  }
}
@media screen and (max-width: 768px) {
  section.testimonials .images .testimonialImages .testimonialImage {
    height: 360px;
  }
}
section.testimonials .images .slick-slide {
  margin-right: 60px;
}
section.testimonials .images .arrows {
  position: absolute;
  height: 60px;
  width: 80px;
  bottom: -60px;
  left: 0;
  display: flex;
}
@media screen and (max-width: 1024px) {
  section.testimonials .images .arrows {
    left: 50%;
    transform: translateX(-50%);
  }
}
section.testimonials .images .arrows .prec,
section.testimonials .images .arrows .next {
  position: relative;
  width: 50%;
  cursor: pointer;
  transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
section.testimonials .images .arrows .prec:after,
section.testimonials .images .arrows .next:after {
  content: "";
  background-image: url(../images/content/icons/arrow_btn_white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  display: block;
  width: 20px;
  height: 10px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
section.testimonials .images .arrows .prec:hover,
section.testimonials .images .arrows .next:hover {
  opacity: 0.6;
}
section.testimonials .images .arrows .prec:after {
  transform: translateY(-50%) rotate(180deg);
  right: auto;
}

.bottomBlue {
  position: relative;
}
.bottomBlue:after {
  content: "";
  background-color: #67a7e7;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 262px;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .bottomBlue:after {
    top: 150px;
  }
}
.bottomBlue .decoration {
  right: 0;
  top: 262px;
  bottom: 0;
  width: 270px;
  background-position: -5px -5px;
}
@media screen and (max-width: 768px) {
  .bottomBlue .decoration {
    top: 150px;
    width: 149px;
  }
}

@media screen and (max-width: 1800px) {
  #page-machining section.heading .share {
    bottom: 35%;
  }
}
@media screen and (max-width: 768px) {
  #page-machining section.heading .share {
    bottom: 50px;
  }
}
#page-machining section.ourExpertise {
  margin-top: -150px;
}
@media screen and (max-width: 1400px) {
  #page-machining section.ourExpertise {
    margin-top: -100px;
  }
}
@media screen and (max-width: 1024px) {
  #page-machining section.ourExpertise {
    margin-top: -50px;
  }
}
#page-machining section.ourExpertise::after {
  content: normal;
}

#page-carrieres .select-field {
  position: static;
  margin-bottom: 80px;
  max-width: 330px;
}
#page-carrieres #carrieresWrapper > a {
  width: 100%;
}
#page-carrieres #carrieresWrapper > a:first-child {
  border-top: 1px solid rgba(51, 51, 51, 0.3);
}
#page-carrieres #carrieresWrapper > a > .emploi {
  background: #fff;
  border-bottom: 1px solid rgba(51, 51, 51, 0.3);
  transition: border 300ms;
  position: relative;
}
#page-carrieres #carrieresWrapper > a > .emploi > h5 {
  padding: 20px 120px 0px 6%;
  font: 15px/1 "MaisonNeue-Bold", sans-serif;
  text-transform: none;
  transition: color 400ms, background 400ms;
  cursor: pointer;
  margin-bottom: 5px;
}
@media screen and (max-width: 1200px) {
  #page-carrieres #carrieresWrapper > a > .emploi > h5 {
    font-size: 15px;
    padding: 40px 100px 0 6%;
  }
}
@media screen and (max-width: 1024px) {
  #page-carrieres #carrieresWrapper > a > .emploi > h5 {
    font-size: 15px;
    padding: 30px 80px 0 6%;
  }
}
@media screen and (max-width: 768px) {
  #page-carrieres #carrieresWrapper > a > .emploi > h5 {
    font-size: 14px;
    padding-right: 60px;
  }
}
#page-carrieres #carrieresWrapper > a > .emploi > h2 {
  padding: 10px 120px 20px 6%;
  color: #333;
  font-size: 28px;
}
@media screen and (max-width: 1200px) {
  #page-carrieres #carrieresWrapper > a > .emploi > h2 {
    font-size: 25px;
    padding: 10px 100px 40px 6%;
  }
}
@media screen and (max-width: 1024px) {
  #page-carrieres #carrieresWrapper > a > .emploi > h2 {
    font-size: 22px;
    padding: 10px 80px 30px 6%;
  }
}
@media screen and (max-width: 768px) {
  #page-carrieres #carrieresWrapper > a > .emploi > h2 {
    font-size: 18px;
    padding-right: 60px;
  }
}
#page-carrieres #carrieresWrapper > a > .emploi > h2::after {
  content: "";
  background-image: url(../images/content/icons/arrow_btn.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  display: block;
  right: 80px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 30px;
  height: 15px;
}
@media screen and (max-width: 1300px) {
  #page-carrieres #carrieresWrapper > a > .emploi > h2::after {
    right: 30px;
  }
}
@media screen and (max-width: 1024px) {
  #page-carrieres #carrieresWrapper > a > .emploi > h2::after {
    right: 60px;
  }
}
@media screen and (max-width: 768px) {
  #page-carrieres #carrieresWrapper > a > .emploi > h2::after {
    right: 30px;
  }
}
#page-carrieres #carrieresWrapper > a > .emploi:hover > h2, #page-carrieres #carrieresWrapper > a > .emploi.open > h2 {
  color: #67a7e7;
  cursor: pointer;
}
#page-carrieres #carrieresWrapper > a > .emploi.open {
  border-bottom: 2px solid #333;
}
#page-carrieres #carrieresWrapper > a > .emploi.open > h2:before {
  background: transparent !important;
  transform: scaleY(0);
}
@media screen and (max-width: 1400px) {
  #page-carrieres #topFilter {
    bottom: 0;
  }
}

#page-equipement .tableaux {
  margin-top: 215px;
}
#page-equipement table {
  border: 1px solid;
  font-family: "Akkurat", "Times new roman";
  margin-top: 25px;
}
@media screen and (max-width: 500px) {
  #page-equipement table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
#page-equipement table th {
  background-color: #2a3c47;
  color: #fff;
  font-family: "Revolution", "Times new roman";
}
#page-equipement table thead tr th, #page-equipement table thead tr td {
  padding: 20px;
  color: #fff;
  background: #981B1D;
  border: 1px solid #2a3c47;
  font-weight: 700;
}
#page-equipement table tbody tr td, #page-equipement table tbody tr th {
  padding: 12px;
  text-align: center !important;
  border: 1px solid #2a3c47;
  vertical-align: middle !important;
  font: 14px/1.5 "MaisonNeue-Book", sans-serif;
}
#page-equipement table tbody tr td {
  width: 32%;
  margin: 20px 0;
  font-size: 17px;
  line-height: 1.6470588235em;
  color: #777c7f;
}
#page-equipement table tbody tr td:first-child {
  width: 3%;
}
@media screen and (max-width: 1400px) {
  #page-equipement table thead tr th, #page-equipement table thead tr td, #page-equipement table tbody tr td, #page-equipement table tbody tr th {
    padding: 20px 10px;
    font: 14px/1.5 "MaisonNeue-Book", sans-serif;
  }
}
@media screen and (max-width: 1200px) {
  #page-equipement table thead tr th, #page-equipement table thead tr td, #page-equipement table tbody tr td, #page-equipement table tbody tr th {
    padding: 15px 7px;
    font: 13px/1.5 "MaisonNeue-Book", sans-serif;
  }
}
@media screen and (max-width: 600px) {
  #page-equipement table thead tr th, #page-equipement table thead tr td, #page-equipement table tbody tr td, #page-equipement table tbody tr th {
    padding: 10px 5px;
    font: 12px/1.5 "MaisonNeue-Book", sans-serif;
  }
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}