.theLargeNews,
.theNews{
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px;
    @media #{$down768} { margin-left: -15px; margin-right: -15px; }
    .news{
        padding: 0 30px;
        @media #{$down768} { padding: 0 15px; }
        .image{
            width: 100%;
            background-color: $apfDarkBlue;
            padding-bottom: 66%;
            position: relative;
            overflow: hidden;
            span{
                position: absolute;
                display: block;
                height: 100%;
                width: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                transition: transform $ease 0.6s;
            }
        }
        .date{
            padding-top: 40px;
        }
        .title h2{
            font-weight: normal;
            text-transform: none;
            margin-top: 15px;
        }
        &:hover{
            .image{
                span{
                    transform: translate(-50%, -50%) scale(1.1);  
                }
            }
        }
    }
}

.theLargeNews{
    margin-top: -100px;
    position: relative;
    @media #{$down1024} { margin-top: -50px; }
    .news{
        width: 50%;
        @media #{$down600} { width: 100% }
        .image{
            padding-bottom: 83%;
        }
        &:first-child{
            @media #{$down600} { padding-bottom: 70px; } 
        }
    }
}

.theNews{
    .news{
        width: 33.333333333%;
        padding-bottom: 60px;
        @media #{$down768} { width: 50% }
        @media #{$down600} { width: 100% }
    }
}