// UN BOUTON UPLOAD __________
.file-field {
	.uploadBtnWrapper {
		position: relative;
		display: inline-block;

		&:after {
			background-image: none !important;
		}

		input[type=file] {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}

		.btnUpload {
			display: block;
			color: #2a3c47;
			text-align: left;
			border: none;
			cursor: pointer;

			svg {
				position: absolute;
				width: 20px;
				height: 20px;
				top: calc(50% - 10px);
				right: 15px;

				use{
					stroke: #2a3c47;
					fill: none;
				}
			}
		}
	}

	&.multi{
		.uploadBtnWrapper{
			&::before{ content: normal; }
			padding: 0;
			text-transform: none;
			width: 100%;

			&:not(:last-child) {
				margin-bottom: 10px;
			}

			.close{
				display: none;
				position: absolute;
				top: 50%;
				right: 30px;
				height: 40px;
				width: 40px;
				background-color: $apfDarkBlue;
				transform: translate(0,-50%);
				border-radius: 50%;
				transition: opacity 0.3s;
				z-index: 2;
				cursor: pointer;

				@media #{$down600} {
					right: 15px;
					height: 35px;
					width: 35px;
				}

				&::before,
				&::after{
					content: '';
					position: absolute;
					width: 15px;
					height: 2px;
					background-color: white;
					top: 50%;
					left: 50%;
				}
				&::before{ transform: translate(-50%,-50%) rotate(45deg); }
				&::after{ transform: translate(-50%,-50%) rotate(-45deg); }

				&:hover{
					opacity: 0.7;
				}
			}

			.btnUpload {
				height: 100px;
				width: 100%;
				background-color: $apfLightBlue;

				span{
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					padding: 10px 40px;
					padding-right: 100px;
					word-break: break-all;
					width: 100%;
					text-align: left;
					font: 18px/1.1 "MaisonNeue-Book", sans-serif;
					@media #{$down600} { font-size: 15px; }
					@media #{$down400} { font-size: 14px; }
				}
				svg{
					width: 30px;
					height: 30px;
					top: 50%;
					right: 50%;
					transform: translate(50%, -50%);
					transition: opacity 0.3s;
				}
			}

			&:hover{
				padding-right: 0;
			}

			&.notEmpty{
				.close{
					display: block;
				}
				.btnUpload {
					svg{
						display: none;
					}
				}
			}

			&:hover{
				svg{
					opacity: 0.6;
				}
			}
		}
	}
}

.multifield_title{
	font: 18px/45px $akkurat;
	color: $apfDarkBlue;
	margin-bottom: 20px;
}

.envoyer {
	margin-top: 2%;

	@media #{$down1300} {
		margin-top: 3%;
	}
	@media #{$down1024} {
		margin-top: 2%;
	}
}

.btns {
	@media #{$down600} {
		width: 100%;
	}
}
