body{
	font-family: $akkurat;
}

.pageContainer{
	position: relative;
}

//Styles de bases pour faire afficher la grille
.decoration{
	background-image:url(../images/content/bg/grid.png);
	position: absolute;
	z-index: 1;
	display: block;
	pointer-events: none;
	opacity: 0.2;
}

//Fait passer l'élément par dessus les grilles décoratives de l'accueil
.fixGridIndex{
	position: relative;
	z-index: 2;
}

.breadcrumbs {
    margin-bottom: 38px;

    @media #{$down768} { margin-bottom: 20px; }

    li {
        display: inline-block;
        position: relative;
		vertical-align: middle;
		font-family: $revolution;
		text-transform: uppercase;

        &:not(:last-child) { padding-right: 25px; }

        &:not(:last-child):after {
            content: '/';
            position: absolute;
            width: 10px;
            height: 12px;
            top: 1px;
            right: 5px;
			color: white;
        }

        a {
            transition: all $ease 0.6s;

            svg {
                width: 20px;
                height: 20px;

                polyline, line, path { fill: transparent; }
            }

            &:hover { color: $apfLightBlue; }
        }

        a, p {
            display: inline-block;
            margin: 0px;
            color: white;
            font-size: 12px;
			line-height: 1.2em;
			letter-spacing: 0.2em;
        }
        p {
            color: white;
        }
    }
}

.share{
	color: white;
	font-size: 12px;
	display: flex;
	height: 45px;
	align-items: center;
	transition: all $ease 0.6s;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	z-index: 2;
	svg{
		width: 15px;
		height: 15px;
		margin-left: 22px;
		path{
			fill: white;
			transition: all $ease 0.6s;
		}
	}
	&:hover{
		color: $apfLightBlue;
		svg{
			rect, polygon, path{
				fill: $apfLightBlue;
			}
		}
	}
	&.width{
		width: 150px;
	}
}

// STYLES __________

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6{
	font-family: $revolution;
	text-transform: uppercase;
	letter-spacing: 0.02em;
	color: $apfDarkBlue;

	span{
		display: block;
		padding-left: 45px;
	}

	&.light{
		color: white;
	}

	&:first-child{ margin-top: 0; }
	&:last-child{ margin-bottom: 0; }
}

h1, .h1 {
	font-size: 70px;
	line-height: 1.028571428571429em; // 72px / 70px
	margin-bottom: 48px;
	@media #{$down768} { font-size: 60px; }
	@media #{$down500} { font-size: 45px; }
}

h2, .h2 {
	font-size: 45px;
	margin-bottom: 48px;
	@media #{$down768} { font-size: 35px; }
}

h3, .h3 {
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 35px;
	margin-top: 70px;
	@media #{$down768} { font-size: 25px; }
}

h4, .h4 {
	font-size: 22px;
	text-transform: none;
	margin-bottom: 20px;
	margin-top: 45px;
	@media #{$down768} { font-size: 20px; }
}

h5, .h5 {
	font-size: 15px;
	letter-spacing: 0.2em;
	color: $apfBlue;
	margin-bottom: 35px;
}

p {
	margin: 20px 0;
	font-size: 17px;
	line-height: 1.647058823529412em; // 28px / 17px
	color: $textColor;

	&.small {  }

	&:first-child{ margin-top: 0; }
	&:last-child{ margin-bottom: 0; }
}

a {
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
}

blockquote{
	font-size: 30px;
	padding: 10px 0 10px 40px;
	border-left: 4px solid $apfBlue;
	color: $apfBlue;
	font-family: $revolution;
	margin: 20px 0;
	line-height: 1.3333333333333em; //40px/30px
	@media #{$down600} { font-size: 21px; }
}

.list{
	&.w-icon{
		li{
			border-bottom: 1px solid #e5e5e5;
			position: relative;
			padding-left: 0;
			&::before{
				content: normal;
			}
			a{
				display: block;
				padding: 20px 3%;
				font-size: 15px;
				color: $apfDarkBlue;
				font-weight: bold;
				transition: all ease-in 0.3s;
				.listIcon{
					position: absolute;
					right: 40px;
					top: 50%;
					text-transform: uppercase;
					letter-spacing: 1px;
					transform: translateY(-50%);
					svg{
						top: 15px;
						width: 15px;
						.fill{
							fill: $apfBlue;
						}
					}
				}
				&:hover{
					color: $apfDarkBlue;
				}
				&::before,
				&::after{
					content: normal;
				}
			}
		}
	}
}

// Le bouton
.btn {
	display: inline-block;
	position: relative;
	z-index: 1;
	color: $apfDarkBlue;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.1em;
	padding: 16px 46px 12px 14px;
	transition: padding $ease 0.6s;
	&:before{
		content:'';
		position: absolute;
		display: block;
		background-color: $apfOrange;
		transform: translateY(-50%);
		top: 50%;
		left: 0;
		z-index: -1;
		height: 40px;
		width: 40px;
		transition: width $ease 0.6s;

	}
	&:after{
		content: '';
		background-image: url(../images/content/icons/arrow_btn.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: absolute;
		display: block;
		right: 16px;
		top: 50%;
		transform: translateY(-50%);
		width: 20px;
		height: 10px;
	}

	&:hover{
		padding-right: 60px;
		&:before{
			width: 100%;
		}
	}

	&.light{
		color: white;
		&:after{
			background-image: url(../images/content/icons/arrow_btn_white.svg);
		}
	}

	&.blue{
		&:before{
			background-color: $apfLightBlue;
		}
	}

	&.light2{
		color: white;
		&:after{
			background-image: url(../images/content/icons/arrow_btn_white.svg);
		}
		@media #{$down600} {
			color: #000;
			&:after {
				background-image: url(../images/content/icons/arrow_btn.svg);
			}
		}
	}

	&.blue2{
		&:before{
			background-color: $apfLightBlue;
		}
		@media #{$down600} {
			&:before {
				background-color: $apfOrange;
			}
		}
	}

	&.oc-loading::after{
		opacity: 1;
		background-image: none;
		margin-left: 0;
		transform: translate(-50%,-50%);
		top: 14px;
	}
}

.dynamic ul,
ul.liste {
	li{
		margin: 20px 0;
		font-size: 17px;
		color: $textColor;
		padding-left: 18px;
		position: relative;
		&:before{
			display: inline-block;
			content: '';
			border-radius: 0.375rem;
			height: 0.35rem;
			width: 0.35rem;
			background-color: $apfBlue;
			position: absolute;
			left: 0;
			top: 0.3em;
		}
	}
	&.light{
		li{
			color: white;
		}
	}
}

.dynamic li{
	margin: 20px 0;
	font-size: 17px;
	color: $textColor;
	padding-left: 18px;
	position: relative;
	&:before{
		display: inline-block;
		content: '';
		border-radius: 0.375rem;
		height: 0.35rem;
		width: 0.35rem;
		background-color: $apfBlue;
		position: absolute;
		left: 0;
		top: 0.3em;
	}
}

.dynamic a{
	position: relative;
	color: $textColor;
	padding: 10px 0;
	&:before,
	&:after{
		content: '';
		position: absolute;
		height: 2px;
		bottom: 0.7em;
		left: 0;
		transition: width $ease 0.6s;
	}

	&::before{
		width: 100%;
		background-color: $textColor;
		z-index: 1;
	}
	&::after{
		width: 0;
		background-color: $apfBlue;
		z-index: 2;
	}
	&:hover{
		&:after{
			width: 100%;
		}
	}
}

.dynamic p{
	//margin-bottom: 30px;
}

.heading{
	background-color: $apfDarkBlue;
}

.stripe-loading-indicator {
	display: none;
}