#page-machining{

    section.heading{
        .share{
            @media #{$down1800} { bottom: 35%; }
            @media #{$down768} { bottom: 50px; }
        }
    }

    section.ourExpertise{
       margin-top: -150px;
       @media #{$down1400} { margin-top: -100px; }
       @media #{$down1024} { margin-top: -50px; }
       &::after{
            content: normal;
       }
   } 
}