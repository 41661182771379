// Le fond noir de chaque popup ou sidepanel
#overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0);
    visibility: hidden;
	z-index: 9;
    transition: background 500ms ease, visibility 0ms ease 300ms, z-index 0ms ease 700ms;
}

// L' icone de play
.iconPlay {
    position: absolute;
    width: 50px;
    height: 50px;
    border: solid 2px rgba(#fff, 0.5);
    border-radius: 50%;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    z-index: 1;
    transition: border-color 300ms;

    &:after {
        content: "";
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 5px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent rgba(#fff, 0.5);
        transition: border-color 300ms;
    }
}
