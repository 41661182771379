// La transition entre les pages
#page-transition, #landing {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	z-index: 9998;
	background: $apfBlue;
	visibility: hidden;
	transition: opacity 600ms, visibility 0ms ease 600ms;

	svg{
		position: absolute;
		width: 250px;
		height: 73px;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		path, polygon, rect{
			fill: white;
		}
	}
}


// Lors d'un changement de page, on enlève certain élément à l'écran avec une animation
.transition, .chargement {
	#page-transition {
		opacity: 1;
		visibility: visible;
		transition: opacity 600ms ease 1000ms;
	}

	#page-transition, #landing {

	}
}