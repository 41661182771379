.tiroirs {
	margin: 0;
	max-width: none;
	list-style-type: none;

	& > li {
		& > h2 {
			position: relative;
			margin: 0;
			cursor: pointer;
		}

		& > .tiroir {
			display: none;
			max-width: 100%;
			opacity: 0;
			transform: translateY(10px);
			transition: opacity 300ms, transform 300ms;
		}

		&.open {
			& > .tiroir {
				opacity: 1;
				transform: translateY(0);
				transition: transform 500ms ease 100ms, opacity 500ms ease 100ms;
			}
		}
	}
}

.tiroirs.style1 {
	& > li {
		background: #f4f5f6;
		margin-top: 20px;

		& > .toggleTiroir{
			padding: 50px 12%;
			cursor: pointer;
			position: relative;

			@media #{$down1300} { padding-right: 80px; }
			@media #{$down1024} { padding-right: 110px; }
			@media #{$down768} { padding-right: 80px; }

			h6{
				margin-bottom: 18px;
				color: $apfBlue;
				letter-spacing: 0.2em;
				font-size: 15px;
				@media #{$down768} { font-size: 15px; }
				@media #{$down600} { font-size: 14px; }
			}
			h2{
				margin-top: 0;
				text-transform: none;
				font-size: 30px;

				@media #{$down1024} { font-size: 28px; }
				@media #{$down768} { font-size: 25px; }
				@media #{$down600} { font-size: 22px; }

				&::after, &::before {
					content: '';
					position: absolute;
					background: $apfBlue;
				}
	
				&::after {
					width: 22px;
					height: 2px;
					margin-top: -(2px / 2);
					top: 50%;
					right: 80px;
					transition: background 400ms;
	
					@media #{$down1300} { right: 30px; }
					@media #{$down1024} { right: 60px; }
					@media #{$down768}  { right: 30px; }
				}
	
				&::before {
					width: 2px;
					height: 22px;
					margin-top: -(22px / 2);
					top: 50%;
					right: 90px;
					transition: transform 400ms, background 400ms;
	
					@media #{$down1300}	{ right: 40px; }
					@media #{$down1024}	{ right: 70px; }
					@media #{$down768}	{ right: 40px; }
				}
			}
		}

		& > .tiroir {

			.tiroirContenu{
				padding: 50px 10%;
			}
			div {

				.adresse { margin-bottom: 15px; }
				p {
					font-size: 15px;
				}
			}
		}

		&:hover, &.open {

			& > .toggleTiroir{
				background-color: #e3e4e5;
			}

		}

		&.open > .toggleTiroir h3:before {
			background: transparent !important;
			transform: scaleY(0);
		}

		&:first-child{
			margin-top: 0;
		}

		&.withImage{
			.toggleTiroir{
				padding-left: 210px;
				@media #{$down600} { padding-left: 10%; }
			}
			.thumb{
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 170px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				@media #{$down600} { display: none; }
			}
		}
	}

	.share{
		margin-top: 40px;
		color: $textColor;
		svg path{
			fill: $apfDarkBlue;
			transition: all $ease 0.6s;
		}
		&:hover{
			svg path{ fill: $apfBlue }
		}
	}
}
