// CHEMIN VERS LES POLICES DE CARACTÈRES __________
$font-path: "../fonts/";



// LES POLICES DE CARACTÈRES __________
// Elles peuvent être déclarées ici ou dans les meta avec google font (voir meta.htm) et
// elles sont stockées dans les variables (voir variables.scss)

@font-face {
	font-family: 'Akkurat';
	src: url('#{$font-path}/Akkurat/Akkurat-Bold.eot');
	src: url('#{$font-path}/Akkurat/Akkurat-Bold.woff') format('woff'),
		 url('#{$font-path}/Akkurat/Akkurat-Bold.ttf') format('truetype'),
		 url('#{$font-path}/Akkurat/Akkurat-Bold.svg#Akkurat-Bold') format('svg'),
		 url('#{$font-path}/Akkurat/Akkurat-Bold.eot?#iefix') format('embedded-opentype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Akkurat';
	src: url('#{$font-path}/Akkurat/Akkurat-Normal.eot');
	src: url('#{$font-path}/Akkurat/Akkurat-Normal.woff') format('woff'),
		 url('#{$font-path}/Akkurat/Akkurat-Normal.ttf') format('truetype'),
		 url('#{$font-path}/Akkurat/Akkurat-Normal.svg#Akkurat-Normal') format('svg'),
		 url('#{$font-path}/Akkurat/Akkurat-Normal.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Revolution';
	src: url('#{$font-path}/Revolution/Revolution-Bold.eot');
	src: url('#{$font-path}/Revolution/Revolution-Bold.woff2') format('woff2'),
		 url('#{$font-path}/Revolution/Revolution-Bold.woff') format('woff'),
		 url('#{$font-path}/Revolution/Revolution-Bold.eot?#iefix') format('embedded-opentype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Revolution';
	src: url('#{$font-path}/Revolution/Revolution-Regular.eot');
	src: url('#{$font-path}/Revolution/Revolution-Regular.woff2') format('woff2'),
		 url('#{$font-path}/Revolution/Revolution-Regular.woff') format('woff'),
		 url('#{$font-path}/Revolution/Revolution-Regular.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}