// UN TEXTAREA __________
.text-field {
	textarea {
		width: 100%;
		height: 45px;
		min-height: 0;
		padding: 15px 0 0 0;
		background-color: transparent;
		border: none;
		border-bottom: 2px solid $apfDarkBlue;
		font: 18px/1.1 $font1;
		color: $apfDarkBlue;
		resize: none;
		overflow: hidden;
		padding-bottom: 1px;
		-webkit-appearance: none;
		border-radius: 0;

		&:focus {
			min-height: 90px;
			border-bottom-color: $apfBlue;
			border-bottom-width: 3px;
			padding-bottom: 0;
		}
		&.valid {
			min-height: 90px;
			padding-bottom: 0;
			border-bottom: 3px solid $apfBlue;
		}
		&.error{
			border-color: $apfOrange;
		}
	}

	label {
		position: absolute;
		top: 0px;
        left: 0px;
		font: 18px/45px $akkurat;
		color: $apfDarkBlue;
		cursor: text;
		transition: 300ms $ease;
	}

	textarea:focus + label,
	textarea.valid + label {
		top: -12px;
		font-size: 11px;
		line-height: 12px;
		cursor: default;
		pointer-events: none;
		color: #b1b1b1;
	}
}
