.vitrine{
    display: flex;
    flex-wrap: wrap;
    .item{
        width: 50%;
        background-color: $apfDarkBlue;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @media #{$down768} { width: 100%; }
        a{
            display: flex;
            width: 100%;
            height: 220px;
            align-items: center;
            justify-content: center;
            color: white;
            text-align: center;
            font-family: $revolution;
            text-transform: uppercase;
            font-size: 30px;
            transition: all $ease 0.6s;
            &:hover{
                background-color: rgba($apfDarkBlue, 0.6);
            }
        }
    }
}

footer {
    .footerLeft{
        .logoFooter{

            @media #{$down768} {
                text-align: center;
                width: 100%;
            }

            svg{
                width: 230px;
                height: 68px;
                path, polygon, rect{
                    fill: $apfDarkBlue;
                    &.main{
                        fill: $apfBlue;
                    }
                }
            }
        }

        .underLogo{
            color: #969a9c;
            font-size: 17px;
            line-height: 1.588235294117647em; // 27px / 17px

            a{
                color: #969a9c;
                &:hover{
                    text-decoration: underline;
                }
            }

            .map-link {
                color: #2a3c47;
            }

            @media #{$down768} {
                div{
                    text-align: center;
                }
            }

            @media #{$down600} {
                .paddingTopMobile{
                    padding-top:30px;
                }
            }


            .header{
                font-family: $revolution;
                color: $apfBlue;
                text-transform: uppercase;
                letter-spacing: 0.2em;
            }
        }
    }

    .footerRight{
        @media #{$down768} { margin-top: 60px; }
        ul{
            margin-top: -10px;
            li{
                @media #{$down768} { text-align: center; }
                a{
                    position: relative;
                    color: $apfDarkBlue;
                    padding: 10px 0;
                    &:before{
                        content: '';
                        position: absolute;
                        height: 3px;
                        width: 0;
                        bottom: 5px;
                        background-color: $apfBlue;
                        transition: width $ease 0.6s;
                    }
                    &:hover{
                        &:before{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.subFooter{
    background-color: $apfDarkBlue;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #82898e;
    font-size: 11px;
    a{
        color: #82898e;
        transition: color $ease 0.6s;
        &:hover{
            color: white;
        }
    }
    .subFooterRight{
        text-align: right;
        @media #{$down600} { margin-top: 15px; }
    }

    @media #{$down600} {
        .subFooterLeft,
        .subFooterRight{
            text-align: center;
        }
    }
}
