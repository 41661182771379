#page-carrieres {

  .select-field {
    position: static;
    margin-bottom: 80px;
    max-width: 330px;
  }

  #carrieresWrapper {
    & > a {
      width: 100%;

      &:first-child {
        border-top: 1px solid rgba($color2, 0.3);
      }

      & > .emploi {
        background: #fff;
        border-bottom: 1px solid rgba($color2, 0.3);
        transition: border 300ms;
        position: relative;

        & > h5 {
          padding: 20px 120px 0px 6%;
          font: 15px/1 $font1b;
          text-transform: none;
          transition: color 400ms, background 400ms;
          cursor: pointer;
          margin-bottom: 5px;

          @media #{$down1200} {
            font-size: 15px;
            padding: 40px 100px 0 6%;
          }
          @media #{$down1024} {
            font-size: 15px;
            padding: 30px 80px 0 6%;
          }
          @media #{$down768} {
            font-size: 14px;
            padding-right: 60px;
          }
        }

        & > h2 {
          padding: 10px 120px 20px 6%;
          color: $color2;
          font-size: 28px;

          @media #{$down1200} {
            font-size: 25px;
            padding: 10px 100px 40px 6%;
          }
          @media #{$down1024} {
            font-size: 22px;
            padding: 10px 80px 30px 6%;
          }
          @media #{$down768} {
            font-size: 18px;
            padding-right: 60px;
          }

          &::after {
            content: '';
            background-image: url(../images/content/icons/arrow_btn.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: absolute;
            display: block;
            right: 80px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 30px;
            height: 15px;

            @media #{$down1300} {
              right: 30px;
            }
            @media #{$down1024} {
              right: 60px;
            }
            @media #{$down768} {
              right: 30px;
            }
          }
        }

        &:hover,
        &.open {
          & > h2 {
            color: $apfBlue;
            cursor: pointer;
          }
        }

        &.open {
          border-bottom: 2px solid $color2;

          & > h2:before {
            background: transparent !important;
            transform: scaleY(0);
          }
        }
      }
    }
  }

  #topFilter {
    @media #{$down1400} {
      bottom: 0;
    }
  }
}