section.heading{
    position: relative;
    @media #{$down1024} { padding-top: 100px; padding-bottom: 100px; }

    .headingContent{
        h1{
            max-width: 50%;
            @media #{$down1600} { max-width: 70%; }
            @media #{$down1300} { max-width: 100%; }
        }
    }

    .share {
        position: absolute;
        bottom: 30px;
        right: 40px;

        @media #{$down768} { left: 20px; }

    }
    .decoration{
        background-position: 0 6px;
        width: 270px;
        left: 0;
        height: 100%;
        bottom: 0;
        top: 0;
        background-image: url(../images/content/bg/grid_light.png);
        opacity: 0.3;
        @media #{$down768} { width: 149px; }
    }
}

section.mobileImage{
    height: 320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

section.mainText{
    position: relative;
    @media #{$down768} { padding-left: 20px; padding-right: 20px; }

    &.publication{
        min-height: 700px;
        @media #{$down1300} { min-height: 800px; }
        @media #{$down768} { min-height: 0; }
    }

    .leftCol{
        width: 33.33333333%;
        position: absolute;
        top: -150px;
        bottom: 0;
        left: 0;
        z-index: 3;
        text-align: center;
        @media #{$down1300} { top: -50px; }
        @media #{$down768} {
            top: auto;
            width: 100%;
            position: static;
        }

        .decorationImage{
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            max-height: 630px;
            height: 100%;
            @media #{$down768} { display: none; }
        }
        .share{
            display: inline-block;
            color: $textColor;
            font-weight: bold;
            height: auto;
            svg{
                position: relative;
                top: 3px;
                path{
                    fill: $apfDarkBlue;
                    transition: all $ease 0.6s;
                }
            }
            &:hover{
                svg path{
                    fill: $apfBlue;
                }
            }
        }

        .sidePaddingShare {
            padding-top: 50px;
            padding-bottom: 15px;
        }

        .sidePaddingBack {
            padding-top: 15px;
            padding-bottom: 50px;
        }

        hr{
            height: 1px;
            border: 0;
            background-color: #e7e7ea;
        }
    }
}
